import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from './contexts/Auth';
import { Languages } from './locales';
import Routes from './routes';
import { queryClient } from './services/queryClient';
import { GlobalStyle } from './styles/global';
import theme from './themes/colors';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Routes />
            <GlobalStyle />
            <Languages />
            <ToastContainer />
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
