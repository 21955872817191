/* eslint-disable import/no-cycle */
import { Table } from 'semantic-ui-react';

import { translate } from '../../../../locales';
import { IReportFilterParamsProps } from '../../../../types/filters';
import { Report } from '../../../../types/report';
import { SubTableRow } from './components/SubTableRow';

interface SubTableProps {
  query: IReportFilterParamsProps;

  reports?: Report[];
}

export function SubTable({ query, reports }: SubTableProps) {
  return (
    <Table.Cell colSpan="99">
      <Table
        unstackable
        compact
        style={{
          borderRadius: '5px',
          border: '1px solid #00000010',
          boxShadow: '2px 2px 2px #00000010',
        }}
      >
        <Table.Header>
          <Table.Row className="subthead">
            <Table.HeaderCell className="th" />

            <Table.HeaderCell className="th" content="Name" />

            {reports?.[0]?.values?.map(({ trainingType }) => (
              <Table.HeaderCell
                key={trainingType}
                className="th"
                content={trainingType}
                textAlign="center"
              />
            ))}

            <Table.HeaderCell
              className="th"
              content={translate('pages.reports.total')}
              textAlign="center"
            />

            <Table.HeaderCell
              className="th"
              content={translate('pages.reports.totalImmediate')}
              textAlign="center"
            />

            <Table.HeaderCell
              className="th"
              content={translate('pages.reports.generalTotalArea')}
              textAlign="center"
            />
          </Table.Row>
        </Table.Header>

        {reports?.map((report) => (
          <SubTableRow key={report.employee.za} query={query} report={report} />
        ))}
      </Table>
    </Table.Cell>
  );
}
