import { useCallback } from 'react';
import { Route, Routes as Router } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { Content } from '../components/Layout/Content';
import { useAuth } from '../hooks/useAuth';
import {
  Home,
  Development,
  DevelopmentPDF,
  Profiles,
  Structure,
  Uploads,
  Messages,
  Training,
  Employees,
  Reports,
} from '../pages';
import { SplashScreen } from '../pages/SplashScreen';
import { Role } from '../types/roles';

function Routes() {
  const { isSigned, user } = useAuth();

  const getRoutePermission = useCallback(
    (roleId: number) => {
      return user?.roles?.map(({ id }) => id).includes(roleId);
    },
    [user?.roles]
  );

  return (
    <Router>
      {isSigned ? (
        <>
          <Route
            element={
              <Layout>
                <Content />
              </Layout>
            }
          >
            <Route path="/web" element={<Home />} />

            <Route
              path="development/:mandatoryId/:sbuId"
              element={<Development />}
            />

            {getRoutePermission(Role.Structure) && (
              <Route path="/structure" element={<Structure />} />
            )}

            {getRoutePermission(Role.Training) && (
              <Route path="/training" element={<Training />} />
            )}

            {getRoutePermission(Role.Messages) && (
              <Route path="/messages" element={<Messages />} />
            )}

            {getRoutePermission(Role.Profiles) && (
              <Route path="/profiles" element={<Profiles />} />
            )}

            {getRoutePermission(Role.Employees) && (
              <Route path="/employees" element={<Employees />} />
            )}

            {getRoutePermission(Role.Uploads) && (
              <Route path="/uploads" element={<Uploads />} />
            )}

            {getRoutePermission(Role.Reports) && (
              <Route path="/reports" element={<Reports />} />
            )}
          </Route>

          <Route
            path="development/:mandatoryId/:sbuId/print"
            element={<DevelopmentPDF />}
          />
        </>
      ) : (
        <Route path="/login" element={<SplashScreen />} />
      )}
    </Router>
  );
}

export default Routes;
