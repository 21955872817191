import { MandatoriesResponseProps, Mandatory } from '../types/mandatories';
import api from './api';

interface ParamsProps {
  pageNumber?: number;
  pageSize?: number;
}

export async function getMandatoryList({
  pageNumber,
  pageSize,
}: ParamsProps): Promise<MandatoriesResponseProps> {
  const response = await api.get(`/mandatories/`, {
    params: {
      pageNumber,
      pageSize,
    },
  });

  return response.data;
}

export async function getMandatoryListByLoggedUser(): Promise<Mandatory[]> {
  const response = await api.get('/mandatories/loggedUser');

  return response.data;
}

export async function getMandatoryById(id: number): Promise<Mandatory> {
  const response = await api.get(`/mandatories/${id}`);

  return response.data;
}
