import { BiRightArrowAlt, BiLeftArrowAlt } from 'react-icons/bi';
import { FaGraduationCap, FaUserCircle } from 'react-icons/fa';
import { ImTree } from 'react-icons/im';
import { MdHomeFilled, MdMessage, MdPeopleAlt, MdUpload } from 'react-icons/md';
import { TbReportAnalytics } from 'react-icons/tb';
import styled from 'styled-components';

import gdyNameLogo from '../../../assets/goodyear-logo-footer.png';
import gdyWingFootLogo from '../../../assets/wingfoot-logo-yellow.png';

interface NavigationProps {
  show: boolean;
}

interface NavigationActiveProps {
  active: boolean;
}

export const Container = styled.div<NavigationProps>`
  background-color: ${({ theme }) => theme.palette.LAYOUT.SIDEBAR};
  height: 100%;
  width: ${({ show }) => (show ? '180px' : '63px')};
  padding: ${({ show }) => (show ? '25px 22px' : '25px 0')};
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({ show }) => (show ? 'none' : 'center')};

  transition: all ease 0.2s;
  z-index: 30;

  @media (max-width: 460px) {
    left: ${({ show }) => (show ? '0' : '-180px')};
    transition: 0.4s;
  }
`;

export const Navigation = styled.nav<NavigationProps>`
  margin-top: 56px;
`;

export const Menu = styled.ul<NavigationProps>`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;

  li {
    a {
      display: flex;
      align-items: center;
      gap: 16px;
      color: ${({ theme }) => theme.palette.TEXT.CONTRAST_TWO};

      i {
        font-size: 16px;
      }

      span {
        text-decoration: none;
        list-style: none;
        @media (min-width: 461px) {
          display: ${({ show }) => (show ? 'block' : 'none')};
        }
      }
    }

    :first-child {
      display: flex;
      justify-content: ${({ show }) => (show ? 'flex-end' : 'start')};
      color: ${({ theme }) => theme.palette.TEXT.CONTRAST_TWO};
      cursor: pointer;

      @media (max-width: 460px) {
        display: none;
      }
    }
  }
`;

export const Item = styled.li<NavigationActiveProps>`
  margin-bottom: 18px;
  text-decoration: none;
  list-style: none;
  a {
    display: flex;
    align-items: center;
    color: ${({ theme, active }) =>
      active
        ? theme.palette.GENERAL.SECONDARY
        : theme.palette.TEXT.CONTRAST_TWO} !important;
    i {
      font-size: 16px;
    }
  }

  &:first-child {
    display: flex;
    color: ${({ theme }) => theme.palette.TEXT.CONTRAST_TWO};
    cursor: pointer;

    @media (max-width: 460px) {
      display: none;
    }
  }
`;

export const Info = styled.div<NavigationProps>`
  color: ${({ theme }) => theme.palette.TEXT.CONTRAST_TWO};

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  .info__version-and-year {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;

    .info__version {
      display: ${({ show }) => (show ? 'flex' : 'none')};
    }

    .info__year {
    }
  }

  .info__sentence {
    text-align: center;

    display: ${({ show }) => (show ? 'flex' : 'none')};
  }

  @media (max-width: 460px) {
    width: 100%;
    justify-content: center;
  }
`;

export const Logo = styled.div`
  width: 100%;
  height: 30px;
  background-image: url(${gdyWingFootLogo});
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 460px) {
    background-image: url(${gdyNameLogo});
  }
`;

export const ArrowRight = styled(BiRightArrowAlt).attrs((props) => ({
  color: props.theme.palette.TEXT.CONTRAST_TWO,
  ...props,
  size: 18,
}))``;

export const ArrowLeft = styled(BiLeftArrowAlt).attrs((props) => ({
  size: 18,
  color: props.theme.palette.TEXT.CONTRAST_TWO,
  ...props,
}))``;

export const Home = styled(MdHomeFilled).attrs((props) => ({
  ...props,
  size: 18,
  color: props.theme.palette.TEXT.CONTRAST_TWO,
}))<NavigationActiveProps>`
  color: ${({ theme, active }) =>
    active
      ? theme.palette.GENERAL.SECONDARY
      : theme.palette.TEXT.CONTRAST_TWO} !important;
`;

export const Structure = styled(ImTree).attrs((props) => ({
  size: 18,
  ...props,
  color: props.theme.palette.TEXT.CONTRAST_TWO,
}))<NavigationActiveProps>`
  color: ${({ theme, active }) =>
    active
      ? theme.palette.GENERAL.SECONDARY
      : theme.palette.TEXT.CONTRAST_TWO} !important;
`;

export const Training = styled(FaGraduationCap).attrs((props) => ({
  color: props.theme.palette.TEXT.CONTRAST_TWO,
  size: 18,
  ...props,
}))<NavigationActiveProps>`
  color: ${({ theme, active }) =>
    active
      ? theme.palette.GENERAL.SECONDARY
      : theme.palette.TEXT.CONTRAST_TWO} !important;
`;

export const Message = styled(MdMessage).attrs((props) => ({
  ...props,
  backgroundColor: 'transparent',
  color: props.theme.palette.TEXT.CONTRAST_TWO,
  size: 18,
}))<NavigationActiveProps>`
  color: ${({ theme, active }) =>
    active
      ? theme.palette.GENERAL.SECONDARY
      : theme.palette.TEXT.CONTRAST_TWO} !important;
`;

export const Employees = styled(MdPeopleAlt).attrs((props) => ({
  ...props,
  color: props.theme.palette.TEXT.CONTRAST_TWO,
  size: 18,
}))<NavigationActiveProps>`
  color: ${({ theme, active }) =>
    active
      ? theme.palette.GENERAL.SECONDARY
      : theme.palette.TEXT.CONTRAST_TWO} !important;
`;

export const Profiles = styled(FaUserCircle).attrs((props) => ({
  ...props,
  color: props.theme.palette.TEXT.CONTRAST_TWO,
  size: 18,
}))<NavigationActiveProps>`
  color: ${({ theme, active }) =>
    active
      ? theme.palette.GENERAL.SECONDARY
      : theme.palette.TEXT.CONTRAST_TWO} !important;
`;

export const Uploads = styled(MdUpload).attrs((props) => ({
  ...props,
  size: 18,
  color: props.theme.palette.TEXT.CONTRAST_TWO,
  backgroundColor: 'transparent',
}))<NavigationActiveProps>`
  color: ${({ theme, active }) =>
    active
      ? theme.palette.GENERAL.SECONDARY
      : theme.palette.TEXT.CONTRAST_TWO} !important;
`;

export const Reports = styled(TbReportAnalytics).attrs((props) => ({
  backgroundColor: 'transparent',
  size: 18,
  ...props,
  color: props.theme.palette.TEXT.CONTRAST_TWO,
}))<NavigationActiveProps>`
  color: ${({ theme, active }) =>
    active
      ? theme.palette.GENERAL.SECONDARY
      : theme.palette.TEXT.CONTRAST_TWO} !important;
`;
