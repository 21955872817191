import styled from 'styled-components';

interface IProps {
  released?: boolean;
}

export const Container = styled.div<IProps>`
  background-color: #ffffff;
  min-height: 500px;
  min-width: 180px;
  max-width: 210px;
  padding: 20px 10px;
  box-shadow: -1px 1px 6px 0px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};
  border-top: ${({ released, theme }) =>
    `20px solid ${released ? theme.palette.GENERAL.SECONDARY : '#aaaaaa'}`};
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  cursor: pointer;
  transition: all ease 0.2s;

  :hover {
    transform: scale(0.99) translateX(-1%);
    box-shadow: -1px 1px 3px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};
  }

  .mandatoryCard__headerAndTotalDevelopment {
    display: flex;
    flex-direction: column;

    .mandatoryCard__header {
      text-align: center;

      .mandatoryCard__header--title {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .mandatoryCard__header--subtitle {
        font-size: 1rem;
        font-weight: bold;
        color: #999999;
      }
    }

    .mandatoryCard__totalDevelopment {
      text-align: center;

      .mandatoryCard__totalDevelopment--hours {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      .mandatoryCard__totalDevelopment--title {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  .mandatoryCard__trainingList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;

    .mandatoryCard__trainingList--item {
      font-size: 1rem;
      margin-bottom: 1.8rem;
      font-weight: bold;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .mandatoryCard__trainingList--title {
      }
      .mandatoryCard__trainingList--hours {
      }
    }
  }
`;
