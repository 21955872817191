import {
  Button,
  Dropdown,
  Icon,
  Table as TableSemantic,
} from 'semantic-ui-react';

import { Content } from '../../../../types/messages';

interface TableBodyProps {
  messages?: Content[];
  handleFormModalOpen: (messageId: number) => void;
  handleDeleteModalOpen: (messageId: number) => void;
}
function TableBody({
  messages,
  handleFormModalOpen,
  handleDeleteModalOpen,
}: TableBodyProps) {
  return (
    <TableSemantic.Body className="tbody">
      {messages?.map((item, index) => {
        const importedBy = `${item?.importedBy.split('_')[0]} ${
          item?.importedBy.split('_')[1]
        } (${item?.importedBy.split('_')[2]})`;

        return (
          <TableSemantic.Row key={item.id} className="tr noRowspan">
            <TableSemantic.Cell className="td table-id" textAlign="center">
              {index + 1}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td table-sbuId" textAlign="center">
              {item?.sbu.region}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td table-sbuId" textAlign="center">
              {item?.sbu.country}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td table-title">
              {item?.title}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td table-fileName">
              {item?.fileName}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td table-type">
              {importedBy}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td table-code">
              {item?.code}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td table-type">
              {item?.type?.name}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td action">
              <Dropdown
                pointing="right"
                trigger={<Icon name="ellipsis vertical" size="large" />}
                icon={null}
              >
                <Dropdown.Menu>
                  <span className="action-buttons">
                    <Button
                      className="filter-list"
                      icon
                      size="mini"
                      color="yellow"
                      onClick={() => {
                        handleFormModalOpen(item.id);
                      }}
                    >
                      <Icon name="pencil" />
                    </Button>
                    <Button
                      className="filter-list"
                      icon
                      size="mini"
                      color="red"
                      onClick={() => {
                        handleDeleteModalOpen(item.id);
                      }}
                    >
                      <Icon name="trash" />
                    </Button>
                  </span>
                </Dropdown.Menu>
              </Dropdown>
            </TableSemantic.Cell>
          </TableSemantic.Row>
        );
      })}
    </TableSemantic.Body>
  );
}
export { TableBody };
