import styled from 'styled-components';

interface CircleAroundProps {
  deg: number;
}

export const Container = styled.div`
  width: 100%;
  height: 450px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  overflow: auto;

  .circle {
    font-weight: bold;

    width: 110px;
    height: 110px;
    text-align: center;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle.center {
    background-color: ${({ theme }) => theme.palette.GENERAL.PRIMARY};
    color: #ffffff;
    font-size: 1.5rem;
  }
`;

export const CircleAround = styled.div<CircleAroundProps>`
  background-color: #bbb;

  position: absolute;

  transform: ${({ deg }) => `rotate(${deg}deg) translate(150px)`};
  transition: all ease 1s;

  span {
    transform: ${({ deg }) => `rotate(${-deg}deg)`};
  }
`;
