import {
  EmployeeByUserIdProps,
  EmployeesParamsProps,
  EmployeesResponseProps,
  EmployeeTrainingsResponseProps,
} from '../types/employees';
import { SubordinatesResponseProps } from '../types/subordinates';
import api from './api';

interface TrainingByEmployeeId {
  employeeId?: number;
  pageSize?: number;
  pageNumber?: number;
}

export async function getEmployeesList({
  pageNumber,
  pageSize = 10,
  sbuId,
  businessLine,
  group,
  jobTitle,
  mandatoryId,
  userName,
  userId,
}: EmployeesParamsProps): Promise<EmployeesResponseProps> {
  const response = await api.get(`/employees`, {
    params: {
      page: pageNumber,
      size: pageSize,
      mandatoryId,
      sbuId,
      group,
      userId: userId || undefined,
      userName: userName || undefined,
      businessLine: businessLine || undefined,
      jobTitle: jobTitle || undefined,
      sort: 'name,asc',
    },
  });

  return response.data;
}

export async function getTrainingByEmployeeId({
  employeeId,
  pageSize = 10,
  pageNumber = 1,
}: TrainingByEmployeeId): Promise<EmployeeTrainingsResponseProps> {
  const response = await api.get('/time-amount/employee', {
    params: {
      employeeId,
      pageSize,
      pageNumber,
    },
  });

  return response.data;
}

export async function getEmployeeById(id: number) {
  const response = await api.get(`/employees/${id}`);
  return response.data;
}

interface EmployeeByUserIdParamsProps {
  mandatoryId: number;
  sbuId: number;
  userId: string;
}

export async function getEmployeeByUserId({
  mandatoryId,
  sbuId,
  userId,
}: EmployeeByUserIdParamsProps): Promise<EmployeeByUserIdProps> {
  const response = await api.get(`/employees/findByZa`, {
    params: {
      mandatoryId,
      sbuId,
      za: userId,
    },
  });
  return response.data;
}

interface SubordinatesProps {
  mandatoryId?: number;
  sbuId?: number;

  pageNumber?: number;
  pageSize?: number;
}
export async function getListSubordinates({
  mandatoryId,
  sbuId,
  pageNumber,
  pageSize,
}: SubordinatesProps): Promise<SubordinatesResponseProps> {
  const response = await api.get('/employees/subordinates', {
    params: {
      mandatoryId,
      sbuId,
      pageNumber,
      pageSize,
    },
  });

  return response.data;
}
