/* eslint-disable react/no-danger */
import { Divider, Loader, Placeholder } from 'semantic-ui-react';
import { useTheme } from 'styled-components';

import { translate } from '../../locales';
import { InformationCard } from '../Cards/InformationCard';
import { LoaderCentered } from '../LoaderCentered';
import { Container } from './styles';

interface IProps {
  message?: string;
  isLoadingMessage?: boolean;

  isLoadingEmployeeData?: boolean;
  name?: string;
  businessLine?: string;
  jobTitle?: string;
  facilityDescription?: string;
}

export function WelcomeBar({
  message,
  isLoadingMessage,

  isLoadingEmployeeData,
  name,
  jobTitle,
  businessLine,
  facilityDescription,
}: IProps) {
  const theme = useTheme();

  function renderWelcomeMessage() {
    if (isLoadingMessage) {
      return <LoaderCentered />;
    }

    if (message) {
      return (
        <>
          <span dangerouslySetInnerHTML={{ __html: message }} />
          <Divider />
        </>
      );
    }

    return undefined;
  }

  function renderEmployeeName() {
    if (isLoadingEmployeeData) {
      return <Loader active inline size="small" />;
    }

    if (name) {
      return `${translate('pages.home.welcome.title')}, ${name
        ?.split(' ')[0]
        .toLowerCase()}!`;
    }

    return `${translate('pages.home.welcome.title')}!`;
  }

  return (
    <Container>
      <div className="welcome__title">
        <span className="welcome__title--userName">{renderEmployeeName()}</span>
      </div>

      <div className="welcome__description">{renderWelcomeMessage()}</div>

      <InformationCard title={translate('pages.home.employeeData.title')}>
        {isLoadingEmployeeData ? (
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        ) : (
          <>
            <div>{name}</div>
            <div>{businessLine}</div>
            <div>{jobTitle}</div>
            <div>{facilityDescription}</div>
          </>
        )}
      </InformationCard>

      <InformationCard
        iconName="warning sign"
        title={translate('pages.home.noteWelcome.title')}
        bgColor={theme.palette.GENERAL.PRIMARY}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: translate('pages.home.noteWelcome.text'),
          }}
        />
      </InformationCard>
    </Container>
  );
}
