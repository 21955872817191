import { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Confirm, Loader } from 'semantic-ui-react';

import { FilterFormGroup } from '../../components/Form/FilterFormGroup';
import { Toolbar } from '../../components/Toolbar';
import { translate } from '../../locales';
import { getTrainingList } from '../../services/training';
import { ITrainingFilterProps } from '../../types/filters';
import {
  TrainingDataForm,
  TrainingListDataProps,
  Training as TrainingProps,
} from '../../types/training';
import { ContentFilter } from './components/ContentFilter';
import { TrainingFormModal } from './components/FormModal';
import { Table } from './components/Table';
import { useHandleDeleteTraining } from './hooks/useHandleDeleteTraining';
import { Container } from './styles';

function Training() {
  const { control, formState, reset, setValue, handleSubmit } =
    useForm<ITrainingFilterProps>();

  const [openTrainingFormModal, setOpenTrainingFormModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [selectedTrainingData, setSelectedTrainingData] =
    useState<TrainingDataForm>();
  const [selectedTrainingId, setSelectedTrainingId] = useState<number>();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<TrainingListDataProps>({
    sbuId: undefined,
    mandatoryId: undefined,
    code: undefined,
    description: undefined,
    icon: undefined,
    typeId: undefined,
  });

  const { data: trainings, isLoading } = useQuery(
    ['trainings', { page, ...query }],
    async () =>
      getTrainingList({
        page: page - 1,
        size: 10,
        ...query,
      }),
    {
      enabled: !!query.sbuId && !!query.mandatoryId,
    }
  );

  const onSubmit = useCallback((data: ITrainingFilterProps) => {
    setQuery({ ...data });
  }, []);

  function handleClearFilters() {
    reset({
      region: null,
      sbuId: null,
      mandatoryId: null,
      typeId: null,
      code: null,
      icon: null,
      description: null,
    });
    setQuery({});
  }

  function handleTrainingFormModal(training?: TrainingProps) {
    if (training?.id) {
      setSelectedTrainingData({
        id: training?.id,
        region: training?.sbu.region,
        code: training?.code,
        description: training?.description,
        icon: training?.icon,
        sbuId: training?.sbu.id,
        shortDescription: training?.shortDescription,
        typeId: training?.type.id,
        mandatoryId: training?.mandatory?.id,
        sequence: training?.sequence,
      });
    }
    setOpenTrainingFormModal(true);
  }

  function handleTrainingFormModalClose() {
    setSelectedTrainingData(undefined);
    setOpenTrainingFormModal(false);
  }

  function handleConfirmDeleteModalOpen(trainingId: number) {
    setSelectedTrainingId(trainingId);
    setOpenConfirmDeleteModal(true);
  }

  function handleConfirmDeleteModalClose() {
    setSelectedTrainingId(undefined);
    setOpenConfirmDeleteModal(false);
  }

  const { handleDeleteTraining } = useHandleDeleteTraining({
    handleConfirmDeleteModalClose,
  });

  return (
    <>
      <Container>
        <div className="page__title">{translate('menu.training')}</div>
        <Toolbar
          onAddClick={handleTrainingFormModal}
          onApplyClick={handleSubmit(onSubmit)}
          onClearClick={handleClearFilters}
          disableNewButton
        >
          <FilterFormGroup>
            <ContentFilter
              control={control}
              formState={formState}
              setValue={setValue}
            />
          </FilterFormGroup>
        </Toolbar>
        {isLoading ? (
          <Loader active />
        ) : (
          <Table
            page={page}
            setPage={setPage}
            trainings={trainings}
            handleFormModalOpen={handleTrainingFormModal}
            handleDeleteModalOpen={handleConfirmDeleteModalOpen}
          />
        )}
      </Container>
      {openTrainingFormModal && (
        <TrainingFormModal
          initialValues={selectedTrainingData}
          isOpen={openTrainingFormModal}
          onRequestClose={handleTrainingFormModalClose}
          closeModalOnSave={() => setOpenTrainingFormModal(false)}
        />
      )}
      {openConfirmDeleteModal && selectedTrainingId && (
        <Confirm
          header={translate('pages.training.confirmDeleteModalTitle')}
          content={translate('components.confirmDeleteModal.message')}
          cancelButton={{
            content: translate('components.confirmDeleteModal.cancel'),
          }}
          confirmButton={{
            content: translate('components.confirmDeleteModal.delete'),
            negative: true,
          }}
          open={openConfirmDeleteModal}
          onCancel={handleConfirmDeleteModalClose}
          onConfirm={() => {
            handleDeleteTraining.mutateAsync(selectedTrainingId);
          }}
        />
      )}
    </>
  );
}

export { Training };
