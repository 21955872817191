import { AxiosError } from 'axios';

import {
  CreateUpdateMandatoryRequest,
  CreateUpdateMandatoryResponse,
  MandatoryProps,
} from '../types/mandatory';
import api from './api';

interface IMandatoryProps {
  sbuId?: number | null;
}

export async function getMandatorySbu({
  sbuId,
}: IMandatoryProps): Promise<MandatoryProps> {
  const response = await api.get(`/mandatories/sbu/${sbuId}`);
  return response.data;
}

export async function getMandatorySbuFilter({
  sbuId,
}: IMandatoryProps): Promise<MandatoryProps[]> {
  const response = await api.get(`/mandatories/sbu/filter/${sbuId}`);
  return response.data;
}

export async function createMandatoryRequest(
  data: CreateUpdateMandatoryRequest,
  copyMessages: boolean
): Promise<CreateUpdateMandatoryResponse> {
  try {
    const response = await api.post<CreateUpdateMandatoryResponse>(
      '/mandatories',
      data,
      {
        params: {
          copyMessages,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(
        `${error.response?.status}: ${error.response?.statusText}`
      );
    } else {
      throw new Error();
    }
  }
}
