import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { Form, ModalContent } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../../../components/Form/ControllerFormDropdown';
import { SelectAutocomplete } from '../../../../components/Inputs/SelectAutocomplete';
import { useSbuOptions } from '../../../../hooks/useSbuOptions';
import { translate } from '../../../../locales';
import sbus from '../../../../mocks/sbu.json';
import {
  getProfilesTypeList,
  getProfilesGroupsList,
  getProfilesRolesList,
} from '../../../../services/profiles';
import { ProfileDataForm } from '../../../../types/profiles';
import {
  useSelectParams,
  useSelectGroups,
  useSelectRoles,
} from '../../../../utils/useSelectParams';

interface IProps {
  control: Control<ProfileDataForm, object>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (data: ProfileDataForm) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<ProfileDataForm>;
  setValue?: UseFormSetValue<ProfileDataForm>;
  watch: UseFormWatch<ProfileDataForm>;
  formState: FormState<ProfileDataForm>;
}

function FormContent({
  formRef,
  control,
  handleSubmit,
  formState,
  onSubmit,
}: IProps) {
  const watchRegion = useWatch({ control, name: 'region' });

  const { sbusOptions } = useSbuOptions(watchRegion);

  const { data: types } = useQuery(['profilesTypes'], async () => {
    return getProfilesTypeList();
  });

  const { data: groups } = useQuery(['profilesGroups'], async () => {
    return getProfilesGroupsList();
  });

  const { data: roles } = useQuery(['profilesRoles'], async () => {
    return getProfilesRolesList();
  });

  return (
    <ModalContent>
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group grouped>
          <Form.Field label={translate('pages.profiles.sbu')} required />
          <SelectAutocomplete
            controllerControl={control}
            controllerName="region"
            options={sbus ?? []}
            fluid
            required
          />
        </Form.Group>
        <Form.Group grouped>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="sbuId"
            label={translate('pages.training.countryLocation')}
            options={sbusOptions ?? []}
            required={!!watchRegion}
            error={
              formState.errors.sbuId?.type === 'required' &&
              translate('pages.training.form.required.countryLocation')
            }
            disabled={!watchRegion}
            fluid
          />
        </Form.Group>
        <Form.Group grouped>
          <Form.Field label={translate('pages.profiles.type')} required />
          <SelectAutocomplete
            controllerControl={control}
            controllerName="typeId"
            options={useSelectParams(types) ?? []}
            fluid
            required
            error={
              formState.errors.typeId?.type === 'required' &&
              translate('pages.structure.form.required.type')
            }
          />
        </Form.Group>
        <Form.Group grouped>
          <Form.Field
            label={translate('pages.profiles.accessGroup')}
            required
          />
          <SelectAutocomplete
            controllerControl={control}
            controllerName="groupId"
            options={useSelectGroups(groups) ?? []}
            fluid
            required
            error={
              formState.errors.groupId?.type === 'required' &&
              translate('pages.structure.form.required.accessGroup')
            }
          />
        </Form.Group>
        <Controller
          control={control}
          name="description"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Form.Field
                label={translate('pages.profiles.description')}
                required
              />
              <Form.Input
                type="text"
                onChange={onChange}
                value={value}
                error={
                  formState.errors.description?.type === 'required' &&
                  translate('pages.structure.form.required.description')
                }
              />
            </>
          )}
        />
        <Form.Group grouped>
          <Form.Field label={translate('pages.profiles.roles')} required />
          <SelectAutocomplete
            controllerControl={control}
            controllerName="rolesIds"
            options={useSelectRoles(roles) ?? []}
            fluid
            multiple
            required
            error={
              formState.errors.rolesIds?.type === 'required' &&
              translate('pages.structure.form.required.roles')
            }
          />
        </Form.Group>
      </Form>
    </ModalContent>
  );
}

export { FormContent };
