import moment from 'moment';
import { memo } from 'react';
import { Label, Modal, ModalProps, Table } from 'semantic-ui-react';

import { translate } from '../../../../locales';
import { LogResponse, StatusColor } from '../../../../types/import';

type LogViewModalProps = ModalProps & {
  data: LogResponse[];
};

const LogViewModal = memo(function LogViewModal({
  data,
  ...next
}: LogViewModalProps) {
  return (
    <Modal {...next}>
      <Modal.Header>{translate('pages.uploads.logs')}</Modal.Header>
      <Modal.Content>
        <Table className="table" unstackable>
          <Table.Header className="thead">
            <Table.Row className="tr">
              <Table.HeaderCell className="th" scope="col">
                {translate('pages.uploads.createdDate')}
              </Table.HeaderCell>
              <Table.HeaderCell className="th" scope="col">
                {translate('pages.uploads.stage')}
              </Table.HeaderCell>
              <Table.HeaderCell className="th" scope="col">
                {translate('pages.uploads.message')}
              </Table.HeaderCell>
              <Table.HeaderCell className="th" scope="col">
                {translate('pages.uploads.status')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body className="tbody">
            {data &&
              data.map((item) => (
                <Table.Row key={item.id} className="tr noRowspan">
                  <Table.Cell>
                    {moment(item.createdAt).format('DD/MM/YYYY')}
                  </Table.Cell>
                  <Table.Cell>{item.stage}</Table.Cell>
                  <Table.Cell>{item.message}</Table.Cell>
                  <Table.Cell>
                    <Label color={StatusColor[item.status]}>
                      {item.status}
                    </Label>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
});

export { LogViewModal };
