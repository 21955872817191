import { AxiosError } from 'axios';

import {
  ImportParams,
  ImportPaginationResponse,
  ImportRequest,
  LogResponse,
} from '../types/import';
import api from './api';

export async function importsRequest({
  pageSize = 10,
  pageNumber = 0,
  ...props
}: ImportParams): Promise<ImportPaginationResponse> {
  try {
    const { data } = await api.get<ImportPaginationResponse>('/imports', {
      params: {
        pageSize,
        pageNumber,
        sort: 'createdAt,desc',
        ...props,
      },
    });
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(
        `${error.response?.status}: ${error.response?.statusText}`
      );
    } else {
      throw new Error();
    }
  }
}

export async function createImportRequest(data: ImportRequest): Promise<void> {
  try {
    const formData = new FormData();

    formData.append('fileName', data.fileName);
    formData.append('mandatoryYear', String(data.mandatoryYear));
    formData.append('sbuId', String(data.sbuId));
    formData.append('file', data.file);

    await api.post('/imports/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(
        `${error.response?.status}: ${error.response?.statusText}`
      );
    } else {
      throw new Error();
    }
  }
}

export async function findLogRequest(importId: number): Promise<LogResponse[]> {
  try {
    const { data } = await api.get(`/imports/${importId}/logs`);
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(
        `${error.response?.status}: ${error.response?.statusText}`
      );
    } else {
      throw new Error();
    }
  }
}

export async function freezeMandatoryRequest(importId: number): Promise<void> {
  try {
    await api.patch(`/imports/${importId}/freeze`);
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(
        `${error.response?.status}: ${error.response?.statusText}`
      );
    } else {
      throw new Error();
    }
  }
}

export async function unfreezeMandatoryRequest(
  importId: number
): Promise<void> {
  try {
    await api.patch(`/imports/${importId}/unfreeze`);
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(
        `${error.response?.status}: ${error.response?.statusText}`
      );
    } else {
      throw new Error();
    }
  }
}

export async function downloadExportDataRequest(
  importId: number
): Promise<Blob> {
  try {
    const { data } = await api.get<any>(`/imports/download/${importId}`, {
      responseType: 'blob',
    });

    return new Blob([data]);
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(
        `${error.response?.status}: ${error.response?.statusText}`
      );
    } else {
      throw new Error();
    }
  }
}

export async function downloadTemplateRequest(): Promise<Blob> {
  try {
    const { data } = await api.get<any>('/imports/downloadTemplate', {
      responseType: 'blob',
    });
    return new Blob([data]);
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(
        `${error.response?.status}: ${error.response?.statusText}`
      );
    } else {
      throw new Error();
    }
  }
}

export async function downloadManualRequest(): Promise<Blob> {
  try {
    const { data } = await api.get<any>('/imports/downloadManual', {
      responseType: 'blob',
    });
    return new Blob([data]);
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(
        `${error.response?.status}: ${error.response?.statusText}`
      );
    } else {
      throw new Error();
    }
  }
}
