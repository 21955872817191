import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { DropdownItemProps, Input, Loader } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../components/Form/ControllerFormDropdown';
import { ControllerFormField } from '../../components/Form/ControllerFormField';
import { FilterFormGroup } from '../../components/Form/FilterFormGroup';
import { Toolbar } from '../../components/Toolbar';
import { useMandatoryByLoggedUser } from '../../hooks/useMandatoryByLoggedUser';
import { useMandatoryOptions } from '../../hooks/useMandatoryOptions';
import { useSbuOptions } from '../../hooks/useSbuOptions';
import { translate } from '../../locales';
import regionOptions from '../../mocks/sbu.json';
import { getListReport } from '../../services/report';
import { getTrainingTypeList } from '../../services/training';
import { FIELD_REQUIRED_MESSAGE } from '../../settings/constants';
import { IReportFilterParamsProps } from '../../types/filters';
import { Table } from './components/Table';
import { Container } from './styles';

export function Reports() {
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IReportFilterParamsProps>();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<IReportFilterParamsProps>({
    sbuId: undefined,
    mandatoryId: undefined,
    filterUserId: undefined,
    trainingTypesIds: undefined,
  });

  const watchRegion = useWatch({ control, name: 'region' });
  const watchSBUId = useWatch({ control, name: 'sbuId' });

  const { releasedMandatory } = useMandatoryByLoggedUser();

  useEffect(() => {
    setQuery({
      sbuId: releasedMandatory.sbuId,
      mandatoryId: releasedMandatory.mandatoryId,
    });
  }, [releasedMandatory.mandatoryId, releasedMandatory.sbuId]);

  const { sbusOptions } = useSbuOptions(watchRegion as string);

  const { mandatoryOptions } = useMandatoryOptions({
    watchSBU: watchSBUId as number,
    watchRegion: watchRegion as string,
    setValue,
  });

  const { data: reports, isLoading: isLoadingReports } = useQuery(
    ['REPORT_LIST', releasedMandatory, { page, ...query }],
    async () =>
      getListReport({
        page: page - 1,
        ...query,
      }),
    {
      enabled: !!releasedMandatory && !!query.mandatoryId && !!query.sbuId,
      retry: false,
    }
  );

  const { data: trainingTypes } = useQuery(
    'TRAINING_TYPE_ID_OPTIONS',
    async () => getTrainingTypeList()
  );

  const trainingTypeOptions = useMemo(
    (): DropdownItemProps[] | undefined =>
      trainingTypes?.map(({ id, name }) => ({
        key: id,
        text: name,
        value: id,
      })),
    [trainingTypes]
  );

  const onSubmit = useCallback((data: IReportFilterParamsProps) => {
    setQuery({ ...data });
  }, []);

  function handleClearFilters() {
    reset({
      region: null,
      mandatoryId: null,
      sbuId: null,
      filterUserId: null,
      trainingTypesIds: null,
    });

    setQuery({
      sbuId: releasedMandatory.sbuId,
      mandatoryId: releasedMandatory.mandatoryId,
    });
  }

  const renderTable = useCallback(() => {
    if (isLoadingReports) {
      return <Loader active />;
    }
    return (
      <Table reports={reports} page={page} setPage={setPage} query={query} />
    );
  }, [isLoadingReports, page, query, reports]);

  return (
    <Container>
      <div className="page__title">{translate('menu.reports')}</div>
      <Toolbar
        onAddClick={() => undefined}
        onApplyClick={handleSubmit(onSubmit)}
        onClearClick={handleClearFilters}
        disableNewButton
        enablePrintButton={!!reports}
      >
        <FilterFormGroup>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="region"
            label={translate('pages.training.sbu')}
            options={regionOptions}
            required
            error={errors.region && translate(FIELD_REQUIRED_MESSAGE)}
            fluid
          />
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="sbuId"
            label={translate('pages.training.countryLocation')}
            options={sbusOptions}
            required={!!watchRegion}
            error={errors.sbuId && translate(FIELD_REQUIRED_MESSAGE)}
            disabled={!watchRegion}
            fluid
          />
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="mandatoryId"
            label={translate('pages.training.year')}
            options={mandatoryOptions}
            required={!!watchSBUId}
            error={errors.mandatoryId && translate(FIELD_REQUIRED_MESSAGE)}
            disabled={!watchRegion || !watchSBUId}
            fluid
          />
          <ControllerFormField
            controllerControl={control}
            controllerName="filterUserId"
            control={Input}
            label={translate('pages.reports.userId')}
            fluid
          />
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="trainingTypesIds"
            label={translate('pages.reports.trainingTypes')}
            selection
            multiple
            options={trainingTypeOptions}
          />
        </FilterFormGroup>
      </Toolbar>

      {renderTable()}
    </Container>
  );
}
