import {
  Profile,
  ProfileDataForm,
  ProfilesListDataProps,
  ProfilesResponseDataProps,
} from '../types/profiles';
import api from './api';

export async function getProfilesList({
  page,
  sbuId,
  typeId,
}: ProfilesListDataProps): Promise<ProfilesResponseDataProps> {
  const response = await api.get(`/profiles/`, {
    params: { page, sbuId, typeId },
  });
  return response.data;
}

export async function getProfileByIdList(id: number): Promise<Profile> {
  const response = await api.get(`/profiles/${id}`);
  return response.data;
}

export async function getProfilesTypeList() {
  const response = await api.get(`/profiles/types/all-training`);
  return response.data;
}

export async function getProfilesGroupsList() {
  const response = await api.get(`/profile-group`);
  return response.data;
}

export async function getProfilesRolesList() {
  const response = await api.get(`/profile-roles`);
  return response.data;
}

export async function createNewProfile({
  description,
  groupId,
  rolesIds,
  sbuId,
  typeId,
}: ProfileDataForm): Promise<Profile> {
  const data = {
    sbuId,
    rolesIds,
    typeId,
    groupId,
    description,
  };
  const response = await api.post('/profiles/', data);
  return response.data;
}

export async function updateProfile({
  id,
  description,
  groupId,
  rolesIds,
  sbuId,
  typeId,
}: ProfileDataForm): Promise<Profile> {
  const data = {
    id,
    sbuId,
    rolesIds,
    typeId,
    groupId,
    description,
  };
  const response = await api.put(`/profiles/`, data);
  return response.data;
}

export async function deleteProfile(id: number): Promise<void> {
  await api.delete(`/profiles/${id}`);
}
