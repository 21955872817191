import { useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { Modal, ModalProps } from 'semantic-ui-react';

import { ButtonGDY } from '../../../../components/Form/ButtonGDY';
import { translate } from '../../../../locales';
import { queryClient } from '../../../../services/queryClient';
import {
  createNewStructure,
  updateStructure,
} from '../../../../services/structure';
import { Structure, StructureDataForm } from '../../../../types/structure';
import { FormContent } from '../FormContent';

type StructureFormModalProps = ModalProps & {
  isOpen: boolean;
  initialValues?: Structure;
  onRequestClose: () => void;
  closeModalOnSave: () => void;
};

function getInitialValues(initialValues: Structure) {
  return {
    ...initialValues,
    country: initialValues.country,
    language: initialValues.language,
    region: initialValues.region,
  };
}

function StructureFormModal({
  isOpen,
  initialValues,
  onRequestClose,
  closeModalOnSave,
  ...rest
}: StructureFormModalProps) {
  const formRef = useRef<HTMLFormElement>(null);

  const { control, formState, handleSubmit, watch, setValue } =
    useForm<StructureDataForm>({
      ...(initialValues && {
        defaultValues: getInitialValues(initialValues),
      }),
    });

  const createStructure = useMutation(
    async (data: StructureDataForm) => {
      return createNewStructure(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('structures');
        closeModalOnSave();
        toast.success(translate('components.toast.success.create'));
      },
      onError: () => {
        toast.error(translate('components.toast.error.create'));
      },
    }
  );

  const editStructure = useMutation(
    async (data: StructureDataForm) => {
      return updateStructure(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('structures');
        closeModalOnSave();
        toast.success(translate('components.toast.success.edit'));
      },
      onError: () => {
        toast.error(translate('components.toast.error.edit'));
      },
    }
  );

  const onSubmit = useCallback(
    async (data: StructureDataForm) => {
      if (data.id) {
        await editStructure.mutateAsync(data);
      } else {
        await createStructure.mutateAsync(data);
      }
    },
    [editStructure, createStructure]
  );

  return (
    <Modal
      closeIcon
      {...rest}
      open={isOpen}
      onClose={onRequestClose}
      closeOnDimmerClick={false}
      size="small"
    >
      <Modal.Header>{translate('pages.structure.form.title')}</Modal.Header>
      <FormContent
        onSubmit={onSubmit}
        formRef={formRef}
        setValue={setValue}
        watch={watch}
        formState={formState}
        control={control}
        handleSubmit={handleSubmit}
      />
      <Modal.Actions>
        <ButtonGDY
          type="submit"
          onClick={() => {
            formRef.current?.handleSubmit();
          }}
          loading={createStructure.isLoading || editStructure.isLoading}
          disabled={createStructure.isLoading || editStructure.isLoading}
        >
          {translate('components.buttons.saveButton')}
        </ButtonGDY>
      </Modal.Actions>
    </Modal>
  );
}

export { StructureFormModal };
