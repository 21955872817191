/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ItemInterface } from 'react-sortablejs';
import {
  Table as TableSemantic,
  Pagination,
  Icon,
  PaginationProps,
} from 'semantic-ui-react';

import { TableNoDataFound } from '../../../../components/TableNoDataFound';
import { translate } from '../../../../locales';
import { queryClient } from '../../../../services/queryClient';
import { updateTrainingSequence } from '../../../../services/training';
import { Training } from '../../../../types/training';
import { TableBody } from '../TableBody';

interface ITableProps {
  handleFormModalOpen: (training?: Training) => void;
  handleDeleteModalOpen: (trainingId: number) => void;
  trainings: any;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

type TrainingSortableProps = Training & ItemInterface;

function Table({
  handleFormModalOpen,
  handleDeleteModalOpen,
  setPage,
  page,
  trainings,
}: ITableProps) {
  const [trainingSortableList, setTrainingSortableList] = useState<
    TrainingSortableProps[]
  >([]);

  useEffect(() => {
    setTrainingSortableList(trainings?.content);
  }, [page, trainings]);

  useQuery(
    ['TRAINING_SEQUENCE', trainingSortableList],
    async () => {
      const sortedList = trainingSortableList?.map(({ id }, index) => ({
        id,
        sequence: index + 1,
      }));
      if (sortedList) {
        return updateTrainingSequence(sortedList);
      }
      return undefined;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('trainings');
      },
    }
  );

  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps
  ) {
    setPage(activePage as number);
  }
  return (
    <>
      <div className="table__scrollable">
        <TableSemantic className="table" unstackable>
          <TableSemantic.Header className="thead">
            <TableSemantic.Row className="tr">
              <TableSemantic.HeaderCell
                className="th idColTr idCol"
                scope="col"
                textAlign="center"
              >
                {translate('pages.training.id')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell
                className="th iconCol"
                scope="col"
                textAlign="center"
              >
                {translate('pages.training.icon')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell
                className="th region"
                scope="col"
                textAlign="center"
              >
                {translate('pages.training.region')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell
                className="th countryLocation"
                scope="col"
              >
                {translate('pages.training.countryLocation')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th category" scope="col">
                {translate('pages.training.category')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th code" scope="col">
                {translate('pages.training.code')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th description" scope="col">
                {translate('pages.training.shortDescription')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th description" scope="col">
                {translate('pages.training.description')}
              </TableSemantic.HeaderCell>
              <TableSemantic.HeaderCell className="th" content="Reorder" />
              <TableSemantic.HeaderCell className="th action">
                <Icon name="cog" size="large" />
              </TableSemantic.HeaderCell>
            </TableSemantic.Row>
          </TableSemantic.Header>
          <TableBody
            trainingSortableList={trainingSortableList}
            setTrainingSortableList={setTrainingSortableList}
            handleFormModalOpen={handleFormModalOpen}
            handleDeleteModalOpen={handleDeleteModalOpen}
          />
        </TableSemantic>
      </div>
      <div className="table__pagination">
        {trainings?.content ? (
          <Pagination
            page={page}
            activePage={page}
            totalPages={trainings?.totalPages}
            onPageChange={handlePageChange}
          />
        ) : (
          <TableNoDataFound />
        )}
      </div>
    </>
  );
}

export { Table };
