import { Control, FormState, UseFormSetValue, useWatch } from 'react-hook-form';

import { ControllerFormDropdown } from '../../../../components/Form/ControllerFormDropdown';
import { useMandatoryOptions } from '../../../../hooks/useMandatoryOptions';
import { useSbuOptions } from '../../../../hooks/useSbuOptions';
import { translate } from '../../../../locales';
import REGIONS from '../../../../mocks/sbu.json';
import { FIELD_REQUIRED_MESSAGE } from '../../../../settings/constants';
import { ImportParams } from '../../../../types/import';

interface IProps {
  control: Control<ImportParams, any>;
  formState: FormState<ImportParams>;
  setValue: UseFormSetValue<ImportParams>;
}

export function ContentFilter({ control, formState, setValue }: IProps) {
  const watchRegion = useWatch({ control, name: 'region' });
  const watchSbuId = useWatch({ control, name: 'sbuId' });

  const { sbusOptions } = useSbuOptions(watchRegion as string);

  const { mandatoryOptions } = useMandatoryOptions({
    watchSBU: watchSbuId as number,
    watchRegion: watchRegion as string,
    setValue,
  });

  return (
    <>
      <ControllerFormDropdown
        controllerControl={control}
        controllerName="region"
        label={translate('pages.uploads.sbu')}
        options={REGIONS}
        required
        error={formState.errors.region && translate(FIELD_REQUIRED_MESSAGE)}
        fluid
      />
      <ControllerFormDropdown
        controllerControl={control}
        controllerName="sbuId"
        label={translate('pages.uploads.countryLocation')}
        options={sbusOptions}
        required={!!watchRegion}
        error={formState.errors.sbuId && translate(FIELD_REQUIRED_MESSAGE)}
        disabled={!watchRegion}
        fluid
      />
      <ControllerFormDropdown
        controllerControl={control}
        controllerName="mandatoryId"
        label={translate('pages.uploads.year')}
        options={mandatoryOptions}
        required={!!watchSbuId}
        error={
          formState.errors.mandatoryId && translate(FIELD_REQUIRED_MESSAGE)
        }
        disabled={!watchRegion || !watchSbuId}
        fluid
      />
    </>
  );
}
