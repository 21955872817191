import { useState } from 'react';

import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Container } from './styles';

interface IProps {
  children: React.ReactNode;
}

function Layout({ children }: IProps) {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);

  const handleShow = () => setOpenSidebar((oldValue) => !oldValue);

  return (
    <Container id="layout">
      <Header />
      {children}
      <Sidebar show={openSidebar} handleShow={handleShow} />
    </Container>
  );
}

export { Layout };
