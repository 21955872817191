import { Icon, SemanticICONS } from 'semantic-ui-react';

import { Container } from './styles';

interface IProps {
  bgColor?: string;
  iconName?: SemanticICONS;
  title: string;
  children: React.ReactNode;
}

export function InformationCard({
  bgColor,
  iconName,
  title,
  children,
}: IProps) {
  return (
    <Container bgColor={bgColor}>
      <div className="informationCard__header">
        {iconName && <Icon name="warning sign" />}
        <div className="informationCard__header--title">{title}</div>
      </div>
      <div className="informationCard__description">{children}</div>
    </Container>
  );
}
