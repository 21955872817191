/* eslint-disable import/no-cycle */
import { UseFormSetValue } from 'react-hook-form';
import { useQuery } from 'react-query';

import { getMandatorySbuFilter } from '../services/mandatory';

export interface FilterProps {
  region?: string;
  sbuId?: number;
  mandatoryId?: number;

  userId?: string;
  userName?: string;
  businessLine?: string;

  jobTitle?: string;
  group?: string;
}

interface IProps {
  watchSBU?: number;
  watchRegion?: string;
  setValue: UseFormSetValue<any>;
}

export function useMandatoryOptions({
  watchSBU,
  watchRegion,
  setValue,
}: IProps) {
  const { data: mandatoryOptions } = useQuery(
    ['year', watchSBU],
    async () => {
      const data = await getMandatorySbuFilter({ sbuId: watchSBU });

      setValue('mandatoryId', data?.[0]?.id);

      return data?.map((mandatory) => ({
        key: mandatory.id,
        text: mandatory.year.split('-')[0],
        value: mandatory.id,
      }));
    },
    { enabled: !!watchSBU && !!watchRegion }
  );

  return {
    mandatoryOptions,
  };
}
