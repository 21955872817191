import { Control, FormState, UseFormSetValue, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Input } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../../../components/Form/ControllerFormDropdown';
import { ControllerFormField } from '../../../../components/Form/ControllerFormField';
import { useMandatoryOptions } from '../../../../hooks/useMandatoryOptions';
import { useSbuOptions } from '../../../../hooks/useSbuOptions';
import { translate } from '../../../../locales';
import sbuRegions from '../../../../mocks/sbu.json';
import { getTrainingTypeList } from '../../../../services/training';
import { FIELD_REQUIRED_MESSAGE } from '../../../../settings/constants';
import { ITrainingFilterProps } from '../../../../types/filters';

interface IProps {
  control: Control<ITrainingFilterProps, any>;
  formState: FormState<ITrainingFilterProps>;
  setValue: UseFormSetValue<ITrainingFilterProps>;
}

export function ContentFilter({ control, formState, setValue }: IProps) {
  const watchRegion = useWatch({ control, name: 'region' });
  const watchSBU = useWatch({ control, name: 'sbuId' });

  const { sbusOptions } = useSbuOptions(watchRegion as string);

  const { mandatoryOptions } = useMandatoryOptions({
    watchSBU: watchSBU as number,
    watchRegion: watchRegion as string,
    setValue,
  });

  const { data: trainingTypeOptions } = useQuery(['trainings'], async () => {
    const data = await getTrainingTypeList();

    return data?.map((item) => ({
      key: item.id,
      text: item.name,
      value: item.id,
    }));
  });

  return (
    <>
      <ControllerFormDropdown
        controllerControl={control}
        controllerName="region"
        label={translate('pages.training.sbu')}
        options={sbuRegions}
        required
        error={formState.errors.region && translate(FIELD_REQUIRED_MESSAGE)}
        fluid
      />
      <ControllerFormDropdown
        controllerControl={control}
        controllerName="sbuId"
        label={translate('pages.training.countryLocation')}
        options={sbusOptions}
        required={!!watchRegion}
        error={formState.errors.sbuId && translate(FIELD_REQUIRED_MESSAGE)}
        disabled={!watchRegion}
        fluid
      />
      <ControllerFormDropdown
        controllerControl={control}
        controllerName="mandatoryId"
        label={translate('pages.training.year')}
        options={mandatoryOptions}
        required={!!watchSBU}
        error={
          formState.errors.mandatoryId && translate(FIELD_REQUIRED_MESSAGE)
        }
        disabled={!watchRegion || !watchSBU}
        fluid
      />
      <ControllerFormDropdown
        controllerControl={control}
        controllerName="typeId"
        label={translate('pages.training.category')}
        options={trainingTypeOptions}
        fluid
      />
      <ControllerFormField
        controllerControl={control}
        controllerName="description"
        control={Input}
        label={translate('pages.training.description')}
        isNotUpperCase
        fluid
      />
      <ControllerFormField
        controllerControl={control}
        controllerName="code"
        control={Input}
        label={translate('pages.training.code')}
        fluid
      />
      <ControllerFormField
        controllerControl={control}
        controllerName="icon"
        control={Input}
        label={translate('pages.training.icon')}
        isNotUpperCase
        fluid
      />
    </>
  );
}
