import { Icon as FluentIcon } from '@fluentui/react/lib/Icon';
import { Dispatch } from 'react';
import { ReactSortable } from 'react-sortablejs';
import {
  Button,
  Dropdown,
  Icon,
  Table as TableSemantic,
} from 'semantic-ui-react';

import { Training } from '../../../../types/training';

interface TableBodyProps {
  trainingSortableList: any;
  setTrainingSortableList: Dispatch<any>;
  handleFormModalOpen: (training?: Training) => void;
  handleDeleteModalOpen: (trainingId: number) => void;
}

function TableBody({
  trainingSortableList,
  setTrainingSortableList,
  handleFormModalOpen,
  handleDeleteModalOpen,
}: TableBodyProps) {
  return (
    <ReactSortable
      tag="tbody"
      className="tbody"
      list={trainingSortableList}
      setList={setTrainingSortableList}
      animation={100}
      delay={1}
      swap
      swapClass=""
    >
      {trainingSortableList?.map((item: Training, index: number) => {
        return (
          <TableSemantic.Row key={item?.id} className="tr noRowspan">
            <TableSemantic.Cell className="td table-idTr" textAlign="center">
              {index + 1}
            </TableSemantic.Cell>
            <TableSemantic.Cell className="td table-icon" textAlign="center">
              <FluentIcon iconName={item.icon} style={{ fontSize: 18 }} />
            </TableSemantic.Cell>
            <TableSemantic.Cell className="td table-region" textAlign="center">
              {item?.sbu?.region}
            </TableSemantic.Cell>
            <TableSemantic.Cell className="td table-countryLocation">
              {item?.sbu?.country}
            </TableSemantic.Cell>
            <TableSemantic.Cell className="td table-category">
              {item?.type?.name}
            </TableSemantic.Cell>
            <TableSemantic.Cell className="td table-code">
              {item?.code}
            </TableSemantic.Cell>
            <TableSemantic.Cell className="td table-description">
              {item?.shortDescription}
            </TableSemantic.Cell>
            <TableSemantic.Cell className="td table-description">
              {item?.description}
            </TableSemantic.Cell>
            <TableSemantic.Cell
              className="td reorder"
              content={<Button icon="arrows alternate vertical" />}
              textAlign="center"
            />
            <TableSemantic.Cell className="td action training">
              <Dropdown
                pointing="right"
                trigger={<Icon name="ellipsis vertical" size="large" />}
                icon={null}
                className="dropdown training"
              >
                <Dropdown.Menu>
                  <span className="action-buttons training">
                    <Button
                      icon
                      className="filter-list training edit"
                      size="mini"
                      color="yellow"
                      onClick={() => {
                        handleFormModalOpen(item);
                      }}
                    >
                      <Icon name="pencil" />
                    </Button>
                    <Button
                      className="filter-list training delete"
                      icon
                      size="mini"
                      color="red"
                      onClick={() => {
                        handleDeleteModalOpen(item?.id as number);
                      }}
                    >
                      <Icon name="trash" />
                    </Button>
                  </span>
                </Dropdown.Menu>
              </Dropdown>
            </TableSemantic.Cell>
          </TableSemantic.Row>
        );
      })}
    </ReactSortable>
  );
}

export { TableBody };
