import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Icon, SemanticICONS, Table } from 'semantic-ui-react';

import {
  getListReport,
  getReportTotalHoursAllSubordinates,
  getReportTotalHoursEmployees,
} from '../../../../services/report';
import { IReportFilterParamsProps } from '../../../../types/filters';
import { Report } from '../../../../types/report';
import { SubTable } from '../SubTable';

interface IProps {
  index: number;
  report: Report;
  query: IReportFilterParamsProps;
}

export function TableRow({ index, query, report }: IProps) {
  const [isExpandedRow, setIsExpandedRow] = useState(false);
  const [isGetTotalImmediatesHoursRow, setIsGetTotalImmediatesHoursRow] =
    useState(true);
  const [hasTotalImmediatesHours, setHasTotalImmediatesHours] = useState(false);
  const [isGetTotalHoursAllSubordinates, setIsGetTotalHoursAllSubordinates] =
    useState(false);

  function toggleExpandedRow() {
    setIsExpandedRow((prev) => !prev);
  }

  function getTotalHoursEmployeesRow() {
    setIsGetTotalImmediatesHoursRow((prev) => !prev);
  }

  function getTotalHoursAllSubordinatesRow() {
    setIsGetTotalHoursAllSubordinates((prev) => !prev);
  }

  const { data: reports, isLoading: isLoadingReports } = useQuery(
    [report, query],
    async () =>
      getListReport({
        mandatoryId: report.mandatory.id,
        sbuId: report.mandatory.sbu.id,
        userId: report.employee.za,
      }),
    {
      enabled: !!report && !!query && isExpandedRow,
    }
  );

  const { data: totalHoursEmployees, isLoading: isLoadingTotalHoursEmployees } =
    useQuery(
      ['TOTAL_HOURS_EMPLOYEES_ROW', report],
      async () =>
        getReportTotalHoursEmployees({
          mandatoryId: query?.mandatoryId as number,
          sbuId: query?.sbuId as number,
          managerUserId: report.employee.za,
        }),
      {
        enabled: !!isGetTotalImmediatesHoursRow && !hasTotalImmediatesHours,
        retry: false,
        onSuccess: () => {
          setHasTotalImmediatesHours(true);
        },
      }
    );

  const {
    data: totalHoursAllSubordinates,
    isLoading: isLoadingTotalHoursAllSubordinates,
  } = useQuery(
    [
      'TOTAL_HOURS_ALL_SUBORDINATES_ROW',
      report,
      isGetTotalHoursAllSubordinates,
    ],
    async () =>
      getReportTotalHoursAllSubordinates({
        mandatoryId: query?.mandatoryId as number,
        sbuId: query?.sbuId as number,
        managerUserId: report.employee.za,
      }),
    {
      enabled: !!isGetTotalHoursAllSubordinates || !!totalHoursEmployees,
      retry: false,
    }
  );

  const iconName = useMemo((): SemanticICONS => {
    if (isLoadingReports) {
      return 'sync';
    }

    if (reports?.content.length === 0) {
      return 'dont';
    }

    return `chevron ${isExpandedRow ? 'down' : 'right'}`;
  }, [isExpandedRow, isLoadingReports, reports?.content.length]);

  return (
    <>
      <Table.Row
        key={`${report.bossId} - ${report.employee.id}`}
        className="tr noRowspan"
        style={{ position: 'relative' }}
      >
        <Table.Cell
          className="td collapse_btn"
          content={
            <Icon
              name={iconName}
              link
              circular
              basic
              onClick={toggleExpandedRow}
              loading={isLoadingReports}
            />
          }
        />

        <Table.Cell className="td table-idTr" textAlign="center">
          {index + 1}
        </Table.Cell>

        <Table.Cell className="td" textAlign="center">
          {report.mandatory?.year.split('-')[0]}
        </Table.Cell>

        <Table.Cell className="td" textAlign="center">
          {report.mandatory.sbu?.country}
        </Table.Cell>

        <Table.Cell
          className="td report_employee_name"
          content={
            <Link
              className="linkUnderlined"
              to={`/development/${report.mandatory.id}/${report.mandatory.sbu.id}`}
              state={report.employee?.za}
            >
              {report.employee?.name}
            </Link>
          }
        />

        {report.values?.map(({ trainingType, value }) => (
          <Table.Cell
            key={trainingType}
            className="td"
            textAlign="center"
            content={value}
          />
        ))}

        <Table.Cell className="td" textAlign="center">
          {report.total}
        </Table.Cell>

        <Table.Cell className="td" textAlign="center">
          {(totalHoursEmployees || reports?.content[0]?.totalPerArea) ?? (
            <Icon
              name={`${
                isLoadingTotalHoursEmployees || isLoadingReports
                  ? 'sync'
                  : 'search'
              }`}
              link
              circular
              basic
              onClick={getTotalHoursEmployeesRow}
              loading={isLoadingTotalHoursEmployees || isLoadingReports}
            />
          )}
        </Table.Cell>

        <Table.Cell className="td" textAlign="center">
          {totalHoursAllSubordinates ?? (
            <Icon
              name={`${isLoadingTotalHoursAllSubordinates ? 'sync' : 'search'}`}
              link
              circular
              basic
              onClick={getTotalHoursAllSubordinatesRow}
              loading={isLoadingTotalHoursAllSubordinates}
            />
          )}
        </Table.Cell>
      </Table.Row>

      {!!reports?.content.length && isExpandedRow && !isLoadingReports && (
        <SubTable query={query} reports={reports?.content} />
      )}
    </>
  );
}
