import { IReportFilterParamsProps } from '../../../../types/filters';
import { Report } from '../../../../types/report';
import { TableRow } from '../TableRow';
import { Container } from './styles';

interface TableBodyProps {
  reports?: Report[];
  query: IReportFilterParamsProps;
}

function TableBody({ reports, query }: TableBodyProps) {
  return (
    <Container className="tbody">
      {reports?.map((report, index: number) => {
        return (
          <TableRow
            key={report.employee.id}
            index={index}
            query={query}
            report={report}
          />
        );
      })}
    </Container>
  );
}

export { TableBody };
