import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 10px;
  margin: 20px 0;
  background: ${({ theme }) => theme.palette.BACKGROUND.WHITE};
  box-shadow: 0 5px 3px -3px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};
  border-radius: 5px;

  @media print {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`;
