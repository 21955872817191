import { useState, useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Confirm, Loader } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../components/Form/ControllerFormDropdown';
import { FilterFormGroup } from '../../components/Form/FilterFormGroup';
import { Toolbar } from '../../components/Toolbar';
import { useMandatoryOptions } from '../../hooks/useMandatoryOptions';
import { useSbuOptions } from '../../hooks/useSbuOptions';
import { translate } from '../../locales';
import sbuRegions from '../../mocks/sbu.json';
import {
  getMessageTypesList,
  deleteMessage,
  getMessagesFilteredList,
} from '../../services/messages';
import { queryClient } from '../../services/queryClient';
import { IMessagesFilterProps } from '../../types/filters';
import { useSelectParams } from '../../utils/useSelectParams';
import { MessageFormModal } from './components/FormModal';
import Table from './components/Table';
import { Container } from './styles';

const FIELD_REQUIRED_MESSAGE = 'components.required';

function Messages() {
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm<IMessagesFilterProps>();

  const [openMessageFormModal, setOpenMessageFormModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState<number>();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<IMessagesFilterProps>({});

  const watchSBU = useWatch({ control, name: 'sbuId' });
  const watchRegion = useWatch({ control, name: 'region' });

  const { data: messages, isLoading } = useQuery(
    ['MESSAGES_FILTERED_LIST', { page, ...query }],
    async () =>
      getMessagesFilteredList({
        page: page - 1,
        size: 10,
        region: query.region,
        sbuId: query.sbuId,
        mandatoryId: query.mandatoryId,
      }),
    {
      enabled: !!query.sbuId && !!query.mandatoryId,
    }
  );

  const { data: messagesType } = useQuery('MESSAGES_TYPE', async () => {
    return getMessageTypesList();
  });

  const { sbusOptions } = useSbuOptions(watchRegion as string);

  const { mandatoryOptions } = useMandatoryOptions({
    watchSBU: Number(watchSBU),
    watchRegion: String(watchRegion),
    setValue,
  });

  const onSubmit = useCallback((data: IMessagesFilterProps) => {
    setQuery({ ...data });
  }, []);

  function handleClearFilters() {
    reset({
      region: null,
      sbuId: null,
      mandatoryId: null,
      typeId: null,
    });
    setQuery({});
  }

  function handleMessageFormModal(messageId?: number) {
    if (messageId) {
      setSelectedMessageId(messageId);
    }
    setOpenMessageFormModal(true);
  }

  function handleMessageFormModalClose() {
    setSelectedMessageId(undefined);
    setOpenMessageFormModal(false);
  }

  function handleConfirmDeleteModalOpen(messageId: number) {
    setSelectedMessageId(messageId);
    setOpenConfirmDeleteModal(true);
  }

  function handleConfirmDeleteModalClose() {
    setSelectedMessageId(undefined);
    setOpenConfirmDeleteModal(false);
  }

  const handleDeleteMessage = useMutation(
    async (id: number) => {
      const response = await deleteMessage(id);
      handleConfirmDeleteModalClose();
      return response;
    },
    {
      onSuccess: () => {
        toast.success(translate('components.toast.success.delete'));
        queryClient.invalidateQueries('MESSAGES_FILTERED_LIST');
      },
      onError: () => {
        toast.error(translate('components.toast.error.delete'));
      },
    }
  );
  return (
    <>
      <Container>
        <div className="page__title">{translate('menu.messages')}</div>
        <Toolbar
          onClearClick={handleClearFilters}
          onApplyClick={handleSubmit(onSubmit)}
          onAddClick={handleMessageFormModal}
        >
          <FilterFormGroup>
            <ControllerFormDropdown
              controllerName="region"
              controllerControl={control}
              label={translate('pages.messages.sbu')}
              options={sbuRegions}
              required
              error={errors.region && translate(FIELD_REQUIRED_MESSAGE)}
              fluid
            />
            <ControllerFormDropdown
              controllerControl={control}
              label={translate('pages.messages.countryLocation')}
              controllerName="sbuId"
              options={sbusOptions}
              required={!!watchRegion}
              error={errors.sbuId && translate(FIELD_REQUIRED_MESSAGE)}
              disabled={!watchRegion}
              fluid
            />
            <ControllerFormDropdown
              controllerControl={control}
              controllerName="mandatoryId"
              options={mandatoryOptions}
              required={!!watchSBU}
              disabled={!watchRegion || !watchSBU}
              error={errors.mandatoryId && translate(FIELD_REQUIRED_MESSAGE)}
              label={translate('pages.messages.year')}
              fluid
            />
            <ControllerFormDropdown
              controllerName="typeId"
              controllerControl={control}
              label={translate('pages.messages.type')}
              options={useSelectParams(messagesType)}
              fluid
            />
          </FilterFormGroup>
        </Toolbar>
        {isLoading ? (
          <Loader active size="large" />
        ) : (
          <Table
            page={page}
            setPage={setPage}
            messages={messages}
            handleFormModalOpen={handleMessageFormModal}
            handleDeleteModalOpen={handleConfirmDeleteModalOpen}
          />
        )}
      </Container>
      {openMessageFormModal && (
        <MessageFormModal
          isOpen={openMessageFormModal}
          messageId={selectedMessageId}
          onRequestClose={handleMessageFormModalClose}
          closeModalOnSave={() => setOpenMessageFormModal(false)}
        />
      )}
      {openConfirmDeleteModal && selectedMessageId && (
        <Confirm
          header={translate('pages.messages.confirmDeleteModalTitle')}
          content={translate('components.confirmDeleteModal.message')}
          cancelButton={{
            content: translate('components.confirmDeleteModal.cancel'),
          }}
          confirmButton={{
            content: translate('components.confirmDeleteModal.delete'),
            negative: true,
          }}
          open={openConfirmDeleteModal}
          onCancel={handleConfirmDeleteModalClose}
          onConfirm={() => {
            handleDeleteMessage.mutateAsync(selectedMessageId);
          }}
        />
      )}
    </>
  );
}

export { Messages };
