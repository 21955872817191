import {
  Button,
  Dropdown,
  Icon,
  Label,
  Table as TableSemantic,
} from 'semantic-ui-react';

import { Profile } from '../../../../types/profiles';

interface TableBodyProps {
  profiles?: Profile[];
  handleFormModalOpen: (profile: Profile) => void;
  handleDeleteModalOpen: (profileId: number) => void;
}

function TableBody({
  profiles,
  handleFormModalOpen,
  handleDeleteModalOpen,
}: TableBodyProps) {
  return (
    <TableSemantic.Body className="tbody">
      {profiles?.map((item, index: number) => {
        return (
          <TableSemantic.Row key={item?.id} className="tr noRowspan">
            <TableSemantic.Cell
              className="td table-id profile"
              textAlign="center"
            >
              {index + 1}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td table-group profile">
              {item?.group?.title}
            </TableSemantic.Cell>

            <TableSemantic.Cell
              className="td table-sbu profile"
              textAlign="center"
            >
              {item?.sbu?.region}
            </TableSemantic.Cell>

            <TableSemantic.Cell
              className="td table-sbu profile"
              textAlign="center"
            >
              {item?.sbu?.country}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td table-roles profile">
              <Label.Group size="small">
                {item?.roles?.map((itemR: any) => (
                  <Label key={itemR?.id} content={itemR?.role} />
                ))}
              </Label.Group>
            </TableSemantic.Cell>

            <TableSemantic.Cell
              className="td table-type profile"
              textAlign="center"
            >
              {item?.type?.name}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td table-description profile">
              {item?.description}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td action profile">
              <Dropdown
                icon={null}
                pointing="right"
                className="dropdown profile"
                trigger={<Icon name="ellipsis vertical" size="large" />}
              >
                <Dropdown.Menu>
                  <span className="action-buttons profile">
                    <Button
                      icon
                      color="yellow"
                      size="mini"
                      className="filter-list profile edit"
                      onClick={() => {
                        handleFormModalOpen(item);
                      }}
                    >
                      <Icon name="pencil" />
                    </Button>
                    <Button
                      icon
                      size="mini"
                      color="red"
                      className="filter-list profile delete"
                      onClick={() => {
                        handleDeleteModalOpen(item.id);
                      }}
                    >
                      <Icon name="trash" />
                    </Button>
                  </span>
                </Dropdown.Menu>
              </Dropdown>
            </TableSemantic.Cell>
          </TableSemantic.Row>
        );
      })}
    </TableSemantic.Body>
  );
}

export { TableBody };
