import { Label } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled.div`
  > label {
    font-size: 17px;
    color: '#333333';

    > span {
      color: '#AE1515';
    }
  }
`;

type FieldLabelProps = {
  required?: boolean;
  error?: boolean;
};

export const FieldLabel = styled.label<FieldLabelProps>`
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: ${({ error }) => (error ? '#9f3a38' : 'rgba(0, 0, 0, 0.87)')};
  font-size: 13px !important;
  font-weight: 700;
  text-transform: none;

  ::after {
    content: ${({ required }) => (required ? `'*'` : 'none')};
    margin: ${({ required }) => (required ? '-0.2em 0 0 0.2em' : 'none')};
    color: ${({ required }) => (required ? '#db2828' : 'none')};
  }
`;

type InputProps = {
  isError: boolean;
};

export const Input = styled.div<InputProps>`
  display: flex;

  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;

  color: ${({ isError }) => (isError ? '#9f3a38' : 'none')};
  border-color: ${({ isError }) => (isError ? '#e0b4b4' : 'none')};
  background: ${({ isError }) => (isError ? '#fff6f6' : 'none')};

  > input {
    display: none;
  }

  > label {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 158px;
    height: 40px;

    cursor: pointer;
    color: '#000';
    background-color: '#25332c';
  }
`;

export const File = styled.div`
  display: flex;
  justify-content: space-between;

  height: 40px;

  padding: 0 15px;

  color: '#333333';
  background-color: '#FAFAFA';

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > label {
      margin-left: 10px;

      color: '#666666';
    }
  }

  > button {
    padding-top: 3px;

    background: none;
    border: none;
  }
`;

export const Description = styled.p`
  margin-top: 20px;
  margin-left: 20px;

  font-size: 12px;
  color: '#666666';
`;

export const ErrorMessage = styled(Label)`
  color: #9f3a38 !important;
  border: 1px solid #e0b4b4 !important;
`;
