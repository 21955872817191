import { InputHTMLAttributes, memo } from 'react';
import { TbFileUpload } from 'react-icons/tb';
import { Icon } from 'semantic-ui-react';

import { translate } from '../../../locales';
import {
  Container,
  Input,
  File,
  Description,
  ErrorMessage,
  FieldLabel,
} from './styles';

export type UploadProps = InputHTMLAttributes<HTMLInputElement> & {
  file?: File;
  label?: string;
  required?: boolean;
  description?: string;
  error?: string | any;
};

export const Upload = memo(function Upload({
  file,
  label,
  required,
  description,
  error,
  ...rest
}: UploadProps) {
  return (
    <Container>
      {label && (
        <FieldLabel required={required} error={error}>
          {label}
        </FieldLabel>
      )}
      <Input isError={!!error}>
        <input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          id="fileInput"
          {...rest}
        />
        <label htmlFor="fileInput">
          <TbFileUpload
            size={20}
            color={error ? '#9f3a38' : '#000000'}
            style={{ marginRight: 5 }}
          />
          {translate('pages.uploads.uploadFile')}
        </label>
      </Input>

      {file && (
        <File>
          <div>
            <Icon name="file outline" />
            <label>{file.name}</label>
          </div>
        </File>
      )}

      {description && <Description>{description}</Description>}

      {error && (
        <ErrorMessage pointing basic>
          {error.toString()}
        </ErrorMessage>
      )}
    </Container>
  );
});
