import { Table } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled.section`
  @media print {
    background-color: #ffffff;
    height: auto;
  }

  height: 80vh;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

export const TrainingChart = styled.div`
  background-color: #596070;
  color: #ffffff;
  max-width: 350px;
  padding: 20px;
  flex: 2;

  border-radius: 5px;

  .trainingChart__header {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    .trainingChart__header--title {
    }

    .trainingChart__header--hours {
      font-size: 1.3rem;
      font-weight: bold;
    }
  }

  .trainingChart__list {
    margin: 20px 0;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .trainingChart__listItem {
      font-weight: bold;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .trainingChart__listItem--label {
      }
    }
  }
`;

export const TrainingDescription = styled(Table)`
  background: none !important;
  min-width: 500px;
  max-width: 50vw;
  margin: 0 !important;
  padding: 0 10px;
  flex: 3;
  border: none !important;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  overflow-y: auto;

  @media print {
    border: 1px solid rgba(34, 36, 38, 0.15) !important;

    row {
      outline: 1px solid rgba(34, 36, 38, 0.15);
    }
  }

  .trainingDescription__row {
    height: 5rem;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;

    td {
      padding: 10px;

      .trainingDescription__trainingType {
        font-size: 1rem;
        font-weight: bold;
        padding: 10px 0;
      }

      .trainingDescription__training {
        background-color: #ffffff;
        padding: 10px;
        border-radius: 5px;
      }
    }
  }
`;

export const ContactUs = styled.div`
  @media print {
    border: 1px solid rgba(34, 36, 38, 0.15);
  }

  background-color: #ffffff;
  max-width: 240px;
  padding: 20px;
  border-radius: 5px;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  .contactUs__information {
    font-weight: bold;
    color: ${({ theme }) => theme.palette.GENERAL.PRIMARY};
    font-size: 1.2rem;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contactUs__qrCode {
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
