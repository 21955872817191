import { SemanticCOLORS } from 'semantic-ui-react';

import { Mandatory } from './mandatory';
import { PaginationEmbedded, PaginationFilter } from './pagination';
import { Structure } from './structure';

enum StatusEnum {
  PROCESSING = 'PROCESSING',
  FAILURE = 'FAILURE',
  COMPLETED = 'COMPLETED',
  SUCCESS = 'SUCCESS',
  VALIDATING_DATA = 'VALIDATING_DATA',
  FREEZE = 'FREEZE',
  SAVING_DATA = 'SAVING_DATA',
}

export enum EStatusId {
  Success = 4,
  Saving = 7,
}

export const StatusColor = {
  [StatusEnum.PROCESSING]: 'blue' as SemanticCOLORS,
  [StatusEnum.FAILURE]: 'red' as SemanticCOLORS,
  [StatusEnum.COMPLETED]: 'green' as SemanticCOLORS,
  [StatusEnum.SUCCESS]: 'green' as SemanticCOLORS,
  [StatusEnum.VALIDATING_DATA]: 'yellow' as SemanticCOLORS,
  [StatusEnum.FREEZE]: 'gray' as SemanticCOLORS,
  [StatusEnum.SAVING_DATA]: 'blue' as SemanticCOLORS,
};

export type Status = {
  id: number;
  name: StatusEnum;
};

export type Import = {
  id: number;
  createdAt: string;
  importedBy: string;
  mandatory: Mandatory;
  sbu: Structure;
  status: Status;
};

export type ImportParams = PaginationFilter & {
  createdAt?: string;
  importedBy?: string;
  importStatusId?: number;
  sort?: string;

  region?: string | null;
  sbuId?: number | null;
  mandatoryId?: number | null;
};

type ImportList = {
  importDTOList: Import[];
};

export type ImportPaginationResponse = PaginationEmbedded<ImportList>;

export type LogResponse = {
  id: number;
  createdAt: string;
  importModel: Import;
  message: string;
  stage: string;
  status: StatusEnum;
};

export type ImportRequest = {
  fileName: string;
  file: File;
  sbuId: number;
  mandatoryYear?: string;
  region?: string;
};

export type ImportFormData = ImportRequest;

export type UploadFilter = {
  region?: string | null;
  sbuId?: number | null;
  mandatoryId?: string | null;
};
