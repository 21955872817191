import { useQuery } from 'react-query';

import { getMandatoryDetailsService } from '../services/mandatoryDetailsService';

interface IProps {
  mandatoryId: number;
  sbuId: number;
  userId: string;
}

export function useMandatoryDetails({ mandatoryId, sbuId, userId }: IProps) {
  const { data: mandatoryDetails, isLoading: isLoadingMandatoryDetails } =
    useQuery(
      ['MANDATORY_DETAILS', userId, mandatoryId, sbuId],
      async () =>
        getMandatoryDetailsService({
          mandatoryId,
          sbuId,
          za: userId,
        }),
      {
        retry: false,
      }
    );

  return {
    mandatoryDetails,
    isLoadingMandatoryDetails,
  };
}
