import {
  Content,
  Message,
  MessageDataForm,
  MessagesParamsProps,
  MessagesResponseProps,
} from '../types/messages';
import api from './api';

export async function getMessagesList({
  page,
  size,
  mandatoryId,
  sbuId,
  trainingTypeId,
  typeId,
}: MessagesParamsProps): Promise<MessagesResponseProps> {
  const response = await api.get(`/messages/`, {
    params: {
      mandatoryId,
      sbuId,
      trainingTypeId: trainingTypeId?.join(','),
      typeId,
      page,
      size,
    },
  });
  return response.data;
}

export async function getMessageById(id: number): Promise<Content> {
  const response = await api.get(`/messages/${id}`);

  return response.data;
}

export async function getMessagesFilteredList({
  page,
  size,
  mandatoryId,
  sbuId,
  typeId,
}: MessagesParamsProps): Promise<MessagesResponseProps> {
  const response = await api.get(`/messages/filter`, {
    params: {
      mandatoryId,
      sbuId,
      typeId,
      page,
      size,
    },
  });
  return response.data;
}

export async function getMessageTypesList() {
  const response = await api.get(`/messages/types/`);
  return response.data;
}

interface MessageTypeByNameResponseProps {
  id: number;
  name: string;
}

export async function getMessageTypeByName(
  name: string
): Promise<MessageTypeByNameResponseProps> {
  const response = await api.get(`/messages/types/search/${name}`);
  return response.data;
}

export async function createNewMessage({
  sbuId,
  code,
  title,
  typeId,
  mandatoryId,
  content,
}: MessageDataForm): Promise<Message> {
  const data = {
    sbuId,
    code,
    title,
    typeId,
    mandatoryId,
    content,
  };
  const response = await api.post('/messages/', data);
  return response.data;
}

export async function updateMessage({
  id,
  sbuId,
  code,
  title,
  typeId,
  content,
  mandatoryId,
  employeeId,
  fileName,
}: MessageDataForm): Promise<Message> {
  const data = {
    id,
    sbuId,
    code,
    content,
    title,
    typeId,
    employeeId,
    fileName,
    mandatoryId,
  };
  const response = await api.put(`/messages/`, data);
  return response.data;
}

export async function deleteMessage(id: number): Promise<void> {
  await api.delete(`/messages/${id}`);
}
