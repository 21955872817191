/* eslint-disable prettier/prettier */
import i18n from 'i18next';
import { useMemo } from 'react';
import { initReactI18next } from 'react-i18next';

import { useAuth } from '../hooks/useAuth';
import enUS from './en-US.json';
import esEs from './es-ES.json';
import ptBR from './pt-BR.json';

function Languages() {
  const { user } = useAuth();

  const language = useMemo(() => {
    return user?.sbu?.language;
  }, [user]);

  i18n.use(initReactI18next).init({
    resources: {
      EN_US: { translation: enUS },
      PT_BR: { translation: ptBR },
      ES_ES: { translation: esEs },
    },
    lng: language,
    fallbackLng: 'PT_BR',
    interpolation: { escapeValue: false },
  });
  return null;
}

export { Languages };

export const languages = ['en-US', 'pt-BR', 'es-ES'];

export const translate = (key: string) => {
  return i18n.t(key);
};
