import styled from 'styled-components';

interface IProps {
  bgColor?: string;
}

export const Container = styled.div<IProps>`
  background-color: ${({ bgColor }) => bgColor ?? '#ffffff'};
  color: ${({ bgColor }) => bgColor && '#ffffff'};
  border-radius: 5px;
  margin: 20px 0 0;
  padding: 20px;
  box-shadow: 4px 4px 3px -3px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};
  border: 1px solid #00000020;

  overflow: hidden;

  .informationCard__header {
    display: flex;
    flex-direction: row;

    .informationCard__header--title {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 1rem;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
`;
