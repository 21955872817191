import { Loader } from 'semantic-ui-react';

export function LoaderCentered() {
  return (
    <span
      style={{
        width: '100%',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader active inline />
    </span>
  );
}
