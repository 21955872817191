import {
  Structure,
  StructureDataForm,
  StructuresListDataProps,
  SBUProps,
} from '../types/structure';
import api from './api';

interface IParamsProps {
  region?: string;
}

export async function getSBUsRegionFilterList({
  region,
}: IParamsProps): Promise<SBUProps[]> {
  const response = await api.get('/sbus/filter', {
    params: {
      region,
    },
  });
  return response.data;
}

export async function getStructureList({
  pageNumber,
  pageSize = 10,
}: StructuresListDataProps) {
  const response = await api.get(`/sbus/`, {
    params: { pageNumber, pageSize },
  });
  return response.data;
}

export async function createNewStructure(
  data: StructureDataForm
): Promise<Structure> {
  const response = await api.post('/sbus/', data);
  return response.data;
}

export async function updateStructure(
  data: StructureDataForm
): Promise<Structure> {
  const response = await api.put(`/sbus/`, data);
  return response.data;
}

export async function deleteStructure(id: number): Promise<void> {
  await api.delete(`/sbus/${id}`);
}
