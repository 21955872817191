import {
  Training,
  TrainingDataForm,
  TrainingListDataProps,
  TrainingType,
} from '../types/training';
import api from './api';

export async function getTrainingList({
  page,
  size,
  sbuId,
  mandatoryId,
  code,
  description,
  icon,
  typeId,
}: TrainingListDataProps) {
  const response = await api.get(`/trainings/`, {
    params: {
      page,
      size,

      sbuId: sbuId || undefined,
      mandatoryId: mandatoryId || undefined,
      typeId: typeId || undefined,
      code: code || undefined,
      description: description || undefined,
      icon: icon || undefined,
      sort: 'sequence,asc',
    },
  });
  return response.data;
}

export async function getTrainingTypeList(): Promise<TrainingType[]> {
  const response = await api.get(`/trainings/types/`);
  return response.data;
}

export async function createNewTraining({
  code,
  description,
  icon,
  sbuId,
  shortDescription,
  typeId,
  sequence = 0,
  mandatoryId,
}: TrainingDataForm): Promise<Training> {
  const data = {
    code,
    description,
    icon,
    sbuId,
    shortDescription,
    typeId,
    sequence,
    mandatoryId,
  };
  const response = await api.post('/trainings/', data);
  return response.data;
}

export async function updateTraining({
  id,
  code,
  description,
  icon,
  sbuId,
  shortDescription,
  typeId,
  sequence = 0,
  mandatoryId,
}: TrainingDataForm): Promise<Training> {
  const data = {
    id,
    code,
    description,
    icon,
    sbuId,
    shortDescription,
    typeId,
    sequence,
    mandatoryId,
  };
  const response = await api.put(`/trainings/`, data);
  return response.data;
}

export async function deleteTraining(id: number): Promise<void> {
  await api.delete(`/trainings/${id}`);
}

interface TrainingSequenceDataProps {
  id?: number;
  sequence?: number;
}

export async function updateTrainingSequence(
  data: TrainingSequenceDataProps[]
): Promise<Training> {
  const response = await api.put(`/trainings/sequence`, data);
  return response.data;
}
