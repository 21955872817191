export function useSelectParams(object: any) {
  const length = object?.length;
  const list = [];
  for (let i = 0; i < length; i++) {
    list.push({
      text: object[i]?.name,
      value: object[i]?.id,
    });
  }
  return list;
}

export function useSelectRoles(object: any) {
  const length = object?.length;
  const list = [];
  for (let i = 0; i < length; i++) {
    list.push({
      text: object[i]?.role,
      value: object[i]?.id,
    });
  }
  return list;
}

export function useSelectGroups(object: any) {
  const length = object?.length;
  const list = [];
  for (let i = 0; i < length; i++) {
    list.push({
      text: object[i]?.title,
      value: object[i]?.id,
    });
  }
  return list;
}

export function useSelectTrainings(object: any) {
  const length = object?.length;
  const list = [];
  for (let i = 0; i < length; i++) {
    list.push({
      text: object[i]?.description,
      value: object[i]?.id,
    });
  }
  return list;
}
