import { Dropdown } from 'semantic-ui-react';

import { useAuth } from '../../../hooks/useAuth';
import { translate } from '../../../locales';
import {
  Container,
  LeftContent,
  Logo,
  Title,
  RightContent,
  RightContentInfo,
  Wrapper,
  Avatar,
} from './styles';

function Header() {
  const { user, logout } = useAuth();

  return (
    <Container id="layout__header">
      <LeftContent>
        <Logo />
        <Title>{translate('menu.title')}</Title>
      </LeftContent>

      <RightContent>
        <Wrapper>
          <RightContentInfo>{`${user?.firstName} ${user?.lastName} (${user?.userId})`}</RightContentInfo>
          <RightContentInfo>{user?.smtpEmailAddress}</RightContentInfo>
        </Wrapper>

        <Dropdown
          trigger={
            <Avatar
              src={
                user?.photoBase64
                  ? `data:image/png;base64,${user?.photoBase64}`
                  : `https://ui-avatars.com/api/?rounded=true&background=random&color=random&name=${user?.firstName}-${user?.lastName}`
              }
              alt=""
            />
          }
          pointing="top right"
          icon={null}
        >
          <Dropdown.Menu className="header__profile-menu">
            <Dropdown.Item text="Logout" icon="sign out" onClick={logout} />
          </Dropdown.Menu>
        </Dropdown>
      </RightContent>
    </Container>
  );
}

export { Header };
