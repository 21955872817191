import { useQuery } from 'react-query';

import { getSBUsRegionFilterList } from '../services/structure';

export function useSbuOptions(watchRegion?: string) {
  const { data: sbusOptions } = useQuery(
    ['sbus', watchRegion],
    async () => {
      const data = await getSBUsRegionFilterList({ region: watchRegion });
      return data?.map((sbu) => ({
        key: sbu.id,
        text: sbu.country,
        value: sbu.id,
      }));
    },
    { enabled: !!watchRegion }
  );

  return {
    sbusOptions,
  };
}
