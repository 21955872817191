import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { Form, ModalContent } from 'semantic-ui-react';

import { SelectAutocomplete } from '../../../../components/Inputs/SelectAutocomplete';
import { translate } from '../../../../locales';
import sbu from '../../../../mocks/sbu.json';
import { StructureDataForm } from '../../../../types/structure';

interface IProps {
  control?: Control<StructureDataForm, object>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (data: StructureDataForm) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<StructureDataForm>;
  setValue?: UseFormSetValue<StructureDataForm>;
  watch: UseFormWatch<StructureDataForm>;
  formState: FormState<StructureDataForm>;
}

function FormContent({
  control,
  formRef,
  onSubmit,
  handleSubmit,
  formState,
}: IProps) {
  return (
    <ModalContent>
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group grouped>
          <Form.Field label={translate('pages.structure.sbu')} required />
          <SelectAutocomplete
            controllerControl={control}
            controllerName="region"
            options={sbu ?? []}
            fluid
            required
            error={
              formState.errors.region?.type === 'required' &&
              translate('pages.structure.form.required.region')
            }
          />
        </Form.Group>
        <Controller
          control={control}
          name="country"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Form.Field
                label={translate('pages.structure.country')}
                required
              />
              <Form.Input
                type="text"
                onChange={onChange}
                value={value}
                error={
                  formState.errors.country?.type === 'required' &&
                  translate('pages.structure.form.required.country')
                }
              />
            </>
          )}
        />
        <Controller
          control={control}
          name="language"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <Form.Field
                label={translate('pages.structure.language')}
                required
              />
              <Form.Input
                type="text"
                placeholder="XX-XX"
                onChange={onChange}
                value={value}
                error={
                  formState.errors.language?.type === 'required' &&
                  translate('pages.structure.form.required.language')
                }
              />
            </>
          )}
        />
      </Form>
    </ModalContent>
  );
}

export { FormContent };
