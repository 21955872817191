import animationLoading from '../../assets/loading.gif';
import logoHome from '../../assets/white-logo.png';
import {
  Container,
  LogoContainer,
  LogoGoodyear,
  LoadingContainer,
  Animation,
} from './styles';

function SplashScreen() {
  return (
    <Container>
      <LogoContainer>
        <LogoGoodyear src={logoHome} />
        <br />
      </LogoContainer>
      <LoadingContainer>
        <Animation src={animationLoading} />
      </LoadingContainer>
    </Container>
  );
}

export { SplashScreen };
