import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  gap: 20px;
  flex: 1;
`;

export const MyMandatories = styled.div`
  /* background-color: #e6e6e6; */
  min-height: 100%;
  min-width: 360px;
  flex: 3;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  overflow-x: auto;
  overflow-y: hidden;

  .myMandatories__header {
    background-color: #ffffff;
    min-height: 50px;
    max-height: 50px;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 5px 3px -3px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .myMandatories__header--title {
      font-size: 1.5rem;
      font-weight: bold;

      display: flex;
      gap: 0.5rem;
      align-items: baseline;
    }

    .myMandatories__header--legend {
      font-size: 1.5rem;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .myMandatories__header__legend--square {
        background-color: ${({ theme }) => theme.palette.GENERAL.SECONDARY};
        width: 22px;
        height: 22px;
        box-shadow: 1px 1px 3px
          ${({ theme }) => theme.palette.BACKGROUND.SHADOW};
        border-radius: 5px;
      }
    }
  }

  .myMandatories__list {
    max-width: 45vw;
    padding: 0 10px;

    display: flex;
    flex-direction: row;
    gap: 10px;
    flex: 1;

    overflow-x: auto;
  }
`;

export const MyTeam = styled.div`
  min-height: 100%;
  min-width: 250px;
  max-width: 320px;
  flex: 2;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .myTeam__list {
  }
`;

export const MyTeamHeader = styled.div`
  background-color: #ffffff;
  min-height: 50px;
  max-height: 50px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 5px 3px -3px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .myTeam__header--title {
    font-size: 1.5rem;
    font-weight: bold;

    display: flex;
    gap: 0.5rem;
    align-items: baseline;
  }

  .myTeam__header--legend {
    font-size: 1.5rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    .myTeam__header__legend--square {
      background-color: ${({ theme }) => theme.palette.GENERAL.SECONDARY};
      width: 22px;
      height: 22px;
      box-shadow: 1px 1px 3px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};
      border-radius: 5px;
    }
  }
`;

export const MyTeamContent = styled.div`
  background-color: #e6e6e6;
  height: 100%;
  border-radius: 5px;
  text-align: center;
  border: 1px solid rgba(34, 36, 38, 0.05) !important;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  overflow-y: auto;

  .myTeam__list {
    padding: 20px;

    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
  }

  .myTeam__pagination {
    background-color: #ffffff;
    padding: 10px 8px;
    border-top: 1px solid rgba(34, 36, 38, 0.15) !important;

    display: flex;
    justify-content: center;
  }

  .myTeam__member {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 0px 6px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: all ease 0.2s;

    :hover {
      transform: scale(0.98);
    }

    span {
      text-align: left;
    }

    .myTeam__member--name {
      font-size: 1rem;
      font-weight: bold;
    }

    .myTeam__member--btn {
      color: ${({ theme }) => theme.palette.GENERAL.PRIMARY};
    }
  }
`;
