/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useQuery } from 'react-query';
import { Form, ModalContent } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../../../components/Form/ControllerFormDropdown';
import { FormUpload } from '../../../../components/Form/FormUpload';
import { translate } from '../../../../locales';
import REGIONS from '../../../../mocks/sbu.json';
import { getMandatorySbu } from '../../../../services/mandatory';
import { getSBUsRegionFilterList } from '../../../../services/structure';
import { ImportFormData } from '../../../../types/import';

interface IProps {
  control: Control<ImportFormData, object>;
  formRef: React.RefObject<HTMLFormElement>;
  onSubmit: (data: ImportFormData) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<ImportFormData>;
  setValue: UseFormSetValue<ImportFormData>;
  formState: FormState<ImportFormData>;
  setIsMandatoryYearInputField: Dispatch<SetStateAction<boolean>>;
}

function FormContent({
  control,
  formRef,
  onSubmit,
  handleSubmit,
  formState,
  setValue,
  setIsMandatoryYearInputField,
}: IProps) {
  const watchRegion = useWatch({ control, name: 'region' });
  const watchSbuId = useWatch({ control, name: 'sbuId' });
  const fileWatch = useWatch({ control, name: 'file' });

  const { data: sbusOptions } = useQuery(
    ['sbus', watchRegion],
    async () => {
      const data = await getSBUsRegionFilterList({ region: watchRegion });

      return data?.map((sbu) => ({
        key: sbu.id,
        text: sbu.country,
        value: sbu.id,
      }));
    },
    { enabled: !!watchRegion }
  );

  const { data: mandatoryOptions } = useQuery(
    ['YEAR_2', watchSbuId],
    async () => {
      const data = await getMandatorySbu({ sbuId: watchSbuId });

      return [
        {
          key: data.id,
          text: data.year.split('-')[0],
          value: data.year.split('-')[0],
        },
      ];
    },
    { enabled: !!watchSbuId && !!watchRegion }
  );

  useEffect(() => {
    if (mandatoryOptions) {
      setValue('mandatoryYear', mandatoryOptions?.[0]?.value);
      setIsMandatoryYearInputField(false);
    }
    if (!mandatoryOptions?.length) {
      setValue('mandatoryYear', String(new Date().getFullYear() - 1));
      setIsMandatoryYearInputField(true);
    }
  }, [watchSbuId, mandatoryOptions]);

  return (
    <ModalContent>
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <ControllerFormDropdown
          controllerControl={control}
          controllerName="region"
          label={translate('pages.uploads.sbu')}
          options={REGIONS}
          required
          error={
            formState.errors.region &&
            translate('pages.uploads.form.required.region')
          }
          fluid
        />

        <ControllerFormDropdown
          controllerControl={control}
          controllerName="sbuId"
          label={translate('pages.uploads.countryLocation')}
          options={sbusOptions}
          disabled={!watchRegion}
          required={!!watchRegion}
          error={
            formState.errors.sbuId &&
            translate('pages.uploads.form.required.country')
          }
          fluid
        />

        {watchSbuId && !mandatoryOptions?.length ? (
          <Controller
            control={control}
            name="mandatoryYear"
            render={({ field: { value, onChange } }) => (
              <InputMask
                label={translate('pages.uploads.year')}
                mask="9999"
                value={value}
                onChange={onChange}
                maskChar=""
                required={!!watchSbuId}
                error={formState.errors.mandatoryYear?.message}
              >
                {/* @ts-ignore */}
                {(inputProps) => <Form.Input {...inputProps} />}
              </InputMask>
            )}
          />
        ) : (
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="mandatoryYear"
            label={translate('pages.uploads.year')}
            options={mandatoryOptions}
            disabled
            required
            error={formState.errors.mandatoryYear?.message}
            fluid
          />
        )}

        <Form.Group grouped>
          <FormUpload
            control={control}
            label={translate('pages.uploads.file')}
            name="file"
            file={fileWatch}
            required
            error={formState.errors.file?.message}
          />
        </Form.Group>
      </Form>
    </ModalContent>
  );
}

export { FormContent };
