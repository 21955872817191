import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

@media print {
  @page {
    size: A4 landscape;
    margin: 0;
  }
  
  html,
  body {
    box-sizing: content-box;
    height: 100%;
    width: calc(100% - 4mm);
    margin: 2mm;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    
    
    ::-webkit-scrollbar {
      display: none;
    }

    .page__title {
      font-size: 1.5em;
      font-weight: bold;
      line-height: 1.5em;
      margin: 8px 16px;
    }

    .table__scrollable {
      max-height: 100%;
      max-width: 100%;
      margin: 8px 16px;

      .table {
       .thead {
          background-color: ${({ theme }) => theme.palette.LAYOUT.SIDEBAR};
          position: static !important;
        }

        .tbody {
          .tr {
            height: 3rem !important;
          }
        }

        .th, .td {
          border: 0;
          white-space: normal !important;
        }

        .action {
          display: none;
        }
      }
    }

    .table__pagination {
      display: none;
    }
  }
  
  #layout__content {
    margin: 0;
    padding: 0;
  }

  .topbar, .print__btn, #layout__header, #layout__sidebar {
    display: none;
  }

}

* {
    font-family: 'Barlow', Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
  }

  *:focus{
    outline: 0;
  }

  #root {
    min-height: 100%;
  }

  body {
    background-color: ${({ theme }) => theme.palette.BACKGROUND.DEFAULT};
    box-sizing: border-box;

    .tox-tinymce {
      border: 1px solid rgba(34,36,38,.15);
      border-radius: .28571429rem;

      .tox-editor-container {
        .tox-editor-header {
          border-bottom: 1px solid rgba(34,36,38,.15) !important;
          box-shadow: none !important;
        }
      }
      
      .tox-notifications-container {
        display: none;
      }
      
      .tox-statusbar {
        border-top: 1px solid rgba(34,36,38,.15) !important;

        .tox-statusbar__text-container {
          display: none !important;
        }
      }
    }


    .page__title {
      font-size: 2.5em;
      font-weight: bold;
      line-height: 1em;
    }

    .table__scrollable {
      max-height: 65vh;
      max-width: 100%;

      overflow: auto;

      ::-webkit-scrollbar-track {
          background: none;
      };
      ::-webkit-scrollbar {
          width: 4px;
          scroll-padding-top: 100px;
      };

      .table {
        outline: 0;
        border: 0;
        border-radius: 5px;
        border-collapse: separate;
        border-radius: 5px;
        flex: 1;

        a {
          font-size: 1rem;
        }


        .thead {
          background-color: ${({ theme }) => theme.palette.LAYOUT.SIDEBAR};
          position: sticky;
          top: 0;

          z-index: 1;
          tr {
            .th {
              background: transparent;
              color: ${({ theme }) => theme.palette.BACKGROUND.WHITE};
              font-weight: 600;
            }
            .action{
              background-color: ${({ theme }) => theme.palette.LAYOUT.SIDEBAR};
            }
          }
        }

        .subthead {
          .th {
            background-color: #888888;
            color: ${({ theme }) => theme.palette.BACKGROUND.WHITE};
            font-weight: 600;
          }
          .action{
            background-color: ${({ theme }) => theme.palette.LAYOUT.SIDEBAR};
          }
        }

        .tbody {
          color: ${({ theme }) => theme.palette.SHADES_OF_GREY.PLATINUM};
          :nth-child(odd) {
            background-color: ${({ theme }) => theme.palette.BACKGROUND.LIGHT};
          }
          :hover td[rowspan], tr:hover td {
            background-color: ${({ theme }) =>
              theme.palette.SHADES_OF_GREY.HOVER};
          }

          .noRowspan {
            :nth-child(even) {
              background-color: ${({ theme }) =>
                theme.palette.BACKGROUND.LIGHT};
            }
          }

          .tr {
            height: 5rem;
            font-weight: 600;

            .rowspan {
              :nth-child(even)[rowspan] {
                background-color: ${({ theme }) =>
                  theme.palette.BACKGROUND.LIGHT};
              }
            }
            .td {
              .labels {
                white-space: normal;
                .label {
                  white-space: nowrap;
                }
              }

            }

            .collapse_btn {
              width: 30px;
            }

            .report_employee_name {
              width: 200px !important;
              overflow-x: hidden;
            }

            .action {
              background-color: ${({ theme }) =>
                theme.palette.BACKGROUND.WHITE};
              width: 10px;
              max-width: 10px;
              outline: 1px solid #F2F2F2;
              box-shadow: -2px 2px 8px -2px #333333;
              i {
                cursor: pointer;
              }
              .action-buttons {
                padding: 4px;
                display: flex;
                flex-direction: row;
                gap: 4px !important;
              }
              span > .dropdown {
                display: flex;
                justify-content: center;
                gap: 8px;
                position: sticky;
              }
            }
            .table-description {
                max-width: 600px;
            }

          }
        }
        .th, .td {
          padding: 8px 16px;
          border: 0;
          white-space: nowrap;
        }
        .id {
          min-width: 30px;
          max-width: 30px;
          padding: 10px 8px;
          text-align: center;
        }
        .action {
          width: 40px;
          padding: 10px 8px;
          text-align: center;
          box-shadow: -4px 0 4px #33333320;
          position: sticky;
          right: 0;
        }
        .table-description {
          max-width: 480px;
          white-space: pre-wrap;
        }
      }
    }

    .table__pagination {
      background-color: #FFFFFF;
      padding: 10px 8px;
      border-radius: 0 0 5px 5px;
      border-top: 1px solid rgba(34,36,38,.15) !important;
    }


  }

  .linkUnderlined {
    :hover {
      text-decoration: underline;
    }

  }

  body, input, a, button {
    font-size: 12px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 460px) {
    body {
      .page__title {
        font-size: 1.5em;
        font-weight: bold;
      }
    }
  }
`;
