import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  gap: 20px;
  flex: 1;

  transition: all ease 0.5s;
`;

export const Topbar = styled.div`
  background-color: #ffffff;
  min-height: 50px;
  max-height: 50px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 5px 3px -3px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .topbar--title {
    font-size: 1.5rem;
    font-weight: bold;

    display: flex;
    gap: 0.5rem;
    align-items: baseline;
  }
`;

export const Content = styled.div`
  flex: 1;
`;

export const TopbarAndContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;
