import { Button as ButtonSemantic, ButtonProps } from 'semantic-ui-react';
import styled from 'styled-components';

export const Button = styled(ButtonSemantic)<ButtonProps>`
  background-color: ${({ theme, active }) =>
    active
      ? `${theme.palette.GENERAL.PRIMARY_HOVER} !important`
      : `${theme.palette.GENERAL.PRIMARY} !important`};
  color: ${({ theme }) => `${theme.palette.GENERAL.SECONDARY} !important`};

  :hover {
    background-color: ${({ theme }) =>
      `${theme.palette.GENERAL.PRIMARY_HOVER} !important`};

    transition: 'all ease 0.2s';
  }
`;
