import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import projectPackage from '../../../../package.json';
import { useAuth } from '../../../hooks/useAuth';
import { translate } from '../../../locales';
import { Role } from '../../../types/roles';
import {
  Container,
  Navigation,
  Menu,
  Item,
  Info,
  ArrowLeft,
  ArrowRight,
  Home,
  Profiles,
  Employees,
  Message,
  Reports,
  Structure,
  Training,
  Uploads,
  Logo,
} from './styles';

interface Props {
  show: boolean;
  handleShow: () => void;
}

function Sidebar({ show, handleShow }: Props) {
  const { user } = useAuth();
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  function CloseSidebarIfOpened() {
    if (show) {
      handleShow();
    }
  }

  const getMenuItemPermission = useCallback(
    (roleId: number) => {
      return user?.roles?.map(({ id }) => id).includes(roleId);
    },
    [user?.roles]
  );

  return (
    <Container show={show} id="layout__sidebar">
      <Navigation show={show}>
        <Menu show={show}>
          <Item onClick={handleShow} active={false}>
            {show ? <ArrowLeft /> : <ArrowRight />}
          </Item>

          <Item
            onClick={CloseSidebarIfOpened}
            active={location.pathname === '/web'}
          >
            <Link to="/web">
              <Home active={location.pathname === '/web'} />
              <span>{translate('menu.initialPage')}</span>
            </Link>
          </Item>

          {getMenuItemPermission(Role.Structure) && (
            <Item
              onClick={CloseSidebarIfOpened}
              active={location.pathname === '/structure'}
            >
              <Link to="/structure">
                <Structure active={location.pathname === '/structure'} />
                <span>{translate('menu.structure')}</span>
              </Link>
            </Item>
          )}

          {getMenuItemPermission(Role.Profiles) && (
            <Item
              onClick={CloseSidebarIfOpened}
              active={location.pathname === '/profiles'}
            >
              <Link to="/profiles">
                <Profiles active={location.pathname === '/profiles'} />
                <span>{translate('menu.profiles')}</span>
              </Link>
            </Item>
          )}

          {getMenuItemPermission(Role.Uploads) && (
            <Item
              onClick={CloseSidebarIfOpened}
              active={location.pathname === '/uploads'}
            >
              <Link to="/uploads">
                <Uploads active={location.pathname === '/uploads'} />
                <span>{translate('menu.uploads')}</span>
              </Link>
            </Item>
          )}

          {getMenuItemPermission(Role.Messages) && (
            <Item
              onClick={CloseSidebarIfOpened}
              active={location.pathname === '/messages'}
            >
              <Link to="/messages">
                <Message active={location.pathname === '/messages'} />
                <span>{translate('menu.messages')}</span>
              </Link>
            </Item>
          )}

          {getMenuItemPermission(Role.Training) && (
            <Item
              onClick={CloseSidebarIfOpened}
              active={location.pathname === '/training'}
            >
              <Link to="/training">
                <Training active={location.pathname === '/training'} />
                <span>{translate('menu.training')}</span>
              </Link>
            </Item>
          )}

          {getMenuItemPermission(Role.Employees) && (
            <Item
              onClick={CloseSidebarIfOpened}
              active={location.pathname === '/employees'}
            >
              <Link to="/employees">
                <Employees active={location.pathname === '/employees'} />
                <span>{translate('menu.employees')}</span>
              </Link>
            </Item>
          )}

          {getMenuItemPermission(Role.Reports) && (
            <Item
              onClick={CloseSidebarIfOpened}
              active={location.pathname === '/reports'}
            >
              <Link to="/reports">
                <Reports active={location.pathname === '/reports'} />
                <span>{translate('menu.reports')}</span>
              </Link>
            </Item>
          )}
        </Menu>
      </Navigation>

      <Info show={show}>
        <Logo />

        <div className="info__version-and-year">
          <span className="info__version">v{projectPackage.version}</span>
          <span className="info__year">© {currentYear}</span>
        </div>

        <div className="info__sentence">The Goodyear Tire & Rubber Company</div>
      </Info>
    </Container>
  );
}

export { Sidebar };
