/* eslint-disable react/no-danger */
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { InformationCard } from '../../../../../components/Cards/InformationCard';
import { LoaderCentered } from '../../../../../components/LoaderCentered';
import { getMonochromeColors } from '../../../../../services/theColorApi';
import { MandatoryDetailsResponseProps } from '../../../../../types/mandatoryDetails';
import { GridCard } from '../../GridCard';
import { TrainingTypesChart } from '../../TrainingTypesChart';
import { Benefits, Container, Note } from './styles';

interface IProps {
  mandatoryDetails?: MandatoryDetailsResponseProps;
  isLoadingMandatoryDetails?: boolean;
  noteMessage?: string;
  isLoadingNoteMessage?: boolean;
}

initializeIcons();

export function PageOne({
  mandatoryDetails,
  isLoadingMandatoryDetails,
  noteMessage,
  isLoadingNoteMessage,
}: IProps) {
  const { data: backgroundColors, isLoading: isLoadingBackgroundColors } =
    useQuery(['THE_COLOR_API', mandatoryDetails?.data?.length], async () =>
      getMonochromeColors('0958b3', mandatoryDetails?.data?.length ?? 0)
    );

  const trainingTypeList = useMemo(
    () =>
      mandatoryDetails?.data?.map(({ trainingTypeName }) => trainingTypeName),
    [mandatoryDetails?.data]
  );

  function renderNoteMessage() {
    if (isLoadingNoteMessage) {
      return <LoaderCentered />;
    }

    if (!isLoadingNoteMessage && noteMessage) {
      return (
        <InformationCard iconName="warning sign" title="Importante">
          <span dangerouslySetInnerHTML={{ __html: noteMessage }} />
        </InformationCard>
      );
    }

    return undefined;
  }

  function renderChart() {
    if (isLoadingMandatoryDetails) {
      return <LoaderCentered />;
    }

    if (!isLoadingMandatoryDetails) {
      return (
        <TrainingTypesChart
          categories={trainingTypeList}
          total={mandatoryDetails?.total}
        />
      );
    }

    return undefined;
  }

  function renderTrainings() {
    if (isLoadingMandatoryDetails || isLoadingBackgroundColors) {
      return <LoaderCentered />;
    }

    if (
      !isLoadingMandatoryDetails &&
      !isLoadingBackgroundColors &&
      mandatoryDetails
    ) {
      return mandatoryDetails?.data?.map(
        ({ trainingTypeName, data: trainings, total }, index) => (
          <GridCard
            key={trainingTypeName}
            title={trainingTypeName}
            totalType={total}
            trainings={trainings}
            backgroundColor={
              backgroundColors ? backgroundColors[index] : undefined
            }
          />
        )
      );
    }

    return undefined;
  }

  return (
    <Container id="reportPageOne">
      <Note>
        {renderChart()}

        {renderNoteMessage()}
      </Note>

      <Benefits bgColor={backgroundColors?.at(-1)}>
        {renderTrainings()}
      </Benefits>
    </Container>
  );
}
