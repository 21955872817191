import { ReportResponseDataProps } from '../types/report';
import api from './api';

interface ReportParamsProps {
  mandatoryId?: number | null;
  sbuId?: number | null;
  userId?: string | null;
  filterUserId?: string | null;
  trainingTypesIds?: number[] | null;

  page?: number;
  size?: number;
}

export async function getListReport({
  mandatoryId,
  sbuId,
  userId,
  filterUserId,
  trainingTypesIds,
  page,
  size = 10,
}: ReportParamsProps): Promise<ReportResponseDataProps> {
  const response = await api.get('/report', {
    params: {
      mandatoryId: mandatoryId || undefined,
      sbuId: sbuId || undefined,
      userId: userId || undefined,
      filterUserId: filterUserId || undefined,
      trainingTypesIds: trainingTypesIds?.length
        ? trainingTypesIds?.join(',')
        : undefined,
      page,
      size,
    },
  });

  const reports: ReportResponseDataProps = response.data;

  return {
    ...response.data,
    content: reports.content?.map((report) => ({
      ...report,
      values: report.values.map(({ trainingType, value }) => ({
        trainingType,
        value: Number(value.toFixed(2)),
      })),
    })),
  };
}

interface IReportTotalHoursEmployeesParamsProps {
  mandatoryId: number;
  sbuId: number;
  managerUserId: string;
}

export async function getReportTotalHoursEmployees({
  mandatoryId,
  sbuId,
  managerUserId,
}: IReportTotalHoursEmployeesParamsProps) {
  const response = await api.get('/report/totalArea', {
    params: {
      mandatoryId,
      sbuId,
      managerUserId,
    },
  });

  return response.data;
}

export async function getReportTotalHoursAllSubordinates({
  mandatoryId,
  sbuId,
  managerUserId,
}: IReportTotalHoursEmployeesParamsProps) {
  const response = await api.get('/report/allTotal', {
    params: {
      mandatoryId,
      sbuId,
      managerUserId,
    },
  });

  return response.data;
}
