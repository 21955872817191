import { Editor } from '@tinymce/tinymce-react';
import { useEffect } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import { useQuery } from 'react-query';
import { Form, Modal } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../../../components/Form/ControllerFormDropdown';
import { useMandatoryWithTrainingIdOptions } from '../../../../hooks/useMandatoryWithTrainingIdOptions';
import { useSbuOptions } from '../../../../hooks/useSbuOptions';
import { translate } from '../../../../locales';
import sbus from '../../../../mocks/sbu.json';
import { getMessageTypesList } from '../../../../services/messages';
import { MessageDataForm } from '../../../../types/messages';
import { useSelectParams } from '../../../../utils/useSelectParams';

interface IProps {
  control: Control<MessageDataForm, object>;
  formRef?: React.RefObject<HTMLFormElement>;
  onSubmit: (data: MessageDataForm) => Promise<void>;
  handleSubmit: UseFormHandleSubmit<MessageDataForm>;
  setValue: UseFormSetValue<MessageDataForm>;
  watch: UseFormWatch<MessageDataForm>;
  formState: FormState<MessageDataForm>;
}

function FormContent({
  formRef,
  control,
  formState,
  setValue,
  handleSubmit,
  onSubmit,
}: IProps) {
  const watchRegion = useWatch({ control, name: 'region' });
  const watchSbuId = useWatch({ control, name: 'sbuId' });
  const watchTrainingId = useWatch({ control, name: 'id' });

  const { data: messagesType } = useQuery(['messages'], async () => {
    return getMessageTypesList();
  });

  const { sbusOptions } = useSbuOptions(watchRegion);

  const { mandatoryOptions } = useMandatoryWithTrainingIdOptions({
    watchRegion,
    watchSbuId,
    watchTrainingId,
  });

  useEffect(() => {
    if (mandatoryOptions && !watchTrainingId) {
      setValue('mandatoryId', mandatoryOptions?.[0]?.key);
    }
  }, [watchSbuId, mandatoryOptions, setValue, watchTrainingId]);

  return (
    <Modal.Content>
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group grouped>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="region"
            label={translate('pages.messages.sbu')}
            required
            error={
              formState.errors.region &&
              translate('pages.training.form.required.countryLocation')
            }
            options={sbus}
            fluid
          />
        </Form.Group>
        <Form.Group grouped>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="sbuId"
            label={translate('pages.training.countryLocation')}
            options={sbusOptions}
            required={!!watchRegion}
            error={
              formState.errors.sbuId &&
              translate('pages.training.form.required.countryLocation')
            }
            disabled={!watchRegion}
            fluid
          />
        </Form.Group>
        <Controller
          control={control}
          name="code"
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange } }) => (
            <>
              <Form.Field label={translate('pages.messages.code')} required />
              <Form.Input
                type="text"
                onChange={onChange}
                value={value}
                error={
                  formState.errors.code &&
                  translate('pages.messages.form.required.code')
                }
              />
            </>
          )}
        />
        <Form.Group grouped>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="typeId"
            label={translate('pages.messages.type')}
            options={useSelectParams(messagesType) ?? []}
            required
            error={
              formState.errors.typeId &&
              translate('pages.messages.form.required.type')
            }
            fluid
          />
        </Form.Group>

        <Form.Group grouped>
          <ControllerFormDropdown
            controllerControl={control}
            controllerName="mandatoryId"
            label={translate('pages.training.year')}
            options={mandatoryOptions}
            required={!!watchSbuId}
            disabled
            error={
              formState.errors.mandatoryId && translate('components.required')
            }
            fluid
          />
        </Form.Group>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          name="title"
          render={({ field: { value, onChange } }) => (
            <>
              <Form.Field label={translate('pages.messages.title')} required />
              <Form.Input
                onChange={onChange}
                value={value}
                type="text"
                error={
                  formState.errors.title &&
                  translate('pages.messages.form.required.title')
                }
              />
            </>
          )}
        />

        <Controller
          control={control}
          name="content"
          render={({ field: { value, onChange } }) => (
            <>
              <Form.Field
                label={translate('pages.messages.content')}
                required
              />
              <Editor
                apiKey="np4wgwkbogbjbktt2hfp0j27qhuz0a7x9t6aao9ccr3433kj"
                init={{
                  menubar: false,
                  images_upload_url: 'postAcceptor.php',
                  automatic_uploads: false,
                  file_picker_types: 'file image',
                }}
                onEditorChange={(e) => onChange(e)}
                value={value}
                plugins="image link image code searchreplace visualblocks fullscreen insertdatetime media"
                toolbar="undo redo | link image | bold italic | alignleft aligncenter alignright alignjustify"
              />
            </>
          )}
        />
      </Form>
    </Modal.Content>
  );
}
export { FormContent };
