import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Note = styled.div`
  height: 100%;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  flex: 3;
  gap: 10px;
`;

interface BenefitsProps {
  bgColor?: string;
}

export const Benefits = styled.div<BenefitsProps>`
  background-color: #f1f1f1;
  min-width: 280px;
  height: 100%;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  flex: 2;

  overflow-y: auto;

  @media print {
    border-radius: 0;
  }
`;
