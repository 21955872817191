import { useQuery } from 'react-query';

import { getEmployeeByUserId } from '../../../services/employees';

interface IProps {
  mandatoryId: number;
  sbuId: number;
  userId: string;
}

export function useEmployeeByUserId({ mandatoryId, sbuId, userId }: IProps) {
  const { data: employee, isLoading: isLoadingEmployee } = useQuery(
    ['EMPLOYEE_DATA', sbuId, mandatoryId, userId],
    async () =>
      getEmployeeByUserId({
        mandatoryId: Number(mandatoryId),
        sbuId: Number(sbuId),
        userId: String(userId),
      }),
    {
      enabled: !!sbuId && !!mandatoryId && !!userId,
    }
  );
  return {
    employee,
    isLoadingEmployee,
  };
}
