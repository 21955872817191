import { AxiosError } from 'axios';

import { UserResponse } from '../types/user';
import { UserPermissions } from '../types/userPermissions';
import api from './api';

export async function userInfoRequest(): Promise<UserResponse> {
  try {
    const { data } = await api.get<UserResponse>('/users');
    return data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(
        `${error.response?.status}: ${error.response?.statusText}`
      );
    } else {
      throw new Error();
    }
  }
}

export async function userPermissionsRequest(): Promise<UserPermissions[]> {
  const response = await api.get('/me/permissions');

  return response.data;
}
