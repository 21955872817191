import { createContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../services/api';
import { userInfoRequest, userPermissionsRequest } from '../services/user';
import { ENV } from '../types/env';
import { User } from '../types/user';
import { UserPermissions } from '../types/userPermissions';
import { getPathParams } from '../utils/getPathParams';

type UserProps = User & UserPermissions;

export type AuthContextData = {
  user?: UserProps;
  token?: string;
  isSigned: boolean;
  logout(): void;
};

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<UserProps>();
  const [token, setToken] = useState<string>();
  const navigate = useNavigate();

  async function getUserInfo() {
    const userInfo = await userInfoRequest();
    const userPermission = await userPermissionsRequest();

    setUser({
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
      userId: userInfo?.userId,
      smtpEmailAddress: userInfo?.smtpEmailAddress,
      costCenterDescription: userInfo?.costCenterDescription,
      title: userInfo?.title,
      facilityDescription: userInfo?.facilityDescription,
      photoBase64: userInfo?.photoBase64,

      roles: userPermission?.[0]?.roles,
      sbu: userPermission?.[0]?.sbu,
      type: userPermission?.[0]?.type,
    });

    sessionStorage.setItem(
      'user',
      JSON.stringify({ ...userInfo, ...userPermission })
    );
  }

  async function authentication(jwt: string) {
    sessionStorage.setItem('jwt', jwt);
    setToken(jwt);

    api.defaults.headers.common.jwt = jwt;

    getUserInfo();
  }

  async function logout() {
    sessionStorage.removeItem('jwt');
    setToken(undefined);
    api.defaults.headers.common.jwt = '';

    window.location.href = ENV.LOGOUT_URL;
  }

  function loadSessionStorage() {
    const jwtPathParamValue = getPathParams('jwt');

    if (jwtPathParamValue) {
      authentication(jwtPathParamValue);
      navigate('/web');
    }

    const jwt = sessionStorage.getItem('jwt');
    const sessionUser = sessionStorage.getItem('user');
    const userInfo: UserProps = sessionUser && JSON.parse(sessionUser);

    if (!jwt) {
      setToken(undefined);
      window.location.href = ENV.LOGIN_URL;
      return;
    }

    setToken(jwt);
    api.defaults.headers.common.jwt = jwt;
    setUser(userInfo);

    getUserInfo();
  }

  useEffect(() => {
    loadSessionStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authContextData: AuthContextData = useMemo(() => {
    return {
      user,
      token,
      isSigned: !!token,
      logout,
    };
  }, [user, token]);

  return (
    <AuthContext.Provider value={authContextData}>
      {children}
    </AuthContext.Provider>
  );
}
