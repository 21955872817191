import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getMandatoryListByLoggedUser } from '../services/mandatories';

export function useMandatoryByLoggedUser() {
  const { data: mandatories, isLoading: isLoadingmandatories } = useQuery(
    'MANDATORIES_LIST_BY_LOGGED_USER',
    async () => getMandatoryListByLoggedUser(),
    { retry: false, refetchOnWindowFocus: false }
  );

  const releasedMandatory = useMemo(
    () => ({
      mandatoryId: mandatories?.at(-1)?.id,
      sbuId: mandatories?.at(-1)?.sbu?.id,
    }),
    [mandatories]
  );

  return {
    mandatories,
    isLoadingmandatories,
    releasedMandatory,
  };
}
