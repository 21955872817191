import { useState, useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Confirm, PaginationProps, Loader } from 'semantic-ui-react';

import { ControllerFormDropdown } from '../../components/Form/ControllerFormDropdown';
import { FilterFormGroup } from '../../components/Form/FilterFormGroup';
import { Toolbar } from '../../components/Toolbar';
import { useSbuOptions } from '../../hooks/useSbuOptions';
import { translate } from '../../locales';
import sbuRegions from '../../mocks/sbu.json';
import {
  getProfilesList,
  getProfilesTypeList,
  deleteProfile,
} from '../../services/profiles';
import { queryClient } from '../../services/queryClient';
import { IProfilesFilterProps } from '../../types/filters';
import { Profile, ProfileDataForm } from '../../types/profiles';
import { useSelectParams } from '../../utils/useSelectParams';
import { ProfileFormModal } from './components/FormModal';
import { Table } from './components/Table';
import { Container } from './styles';

function Profiles() {
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<IProfilesFilterProps>();

  const [openProfileFormModal, setOpenProfileFormModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [selectedProfileData, setSelectedProfileData] =
    useState<ProfileDataForm>();
  const [selectedProfileId, setSelectedProfileId] = useState<number>();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<IProfilesFilterProps>({
    sbuId: undefined,
    typeId: undefined,
  });
  const watchRegion = useWatch({ control, name: 'region' });

  const { data: profiles, isLoading } = useQuery(
    ['profiles', { page, ...query }],
    async () => {
      return getProfilesList({
        page: page - 1,
        ...query,
      });
    }
  );

  const { data: profilesTypes } = useQuery(['profiles'], async () => {
    return getProfilesTypeList();
  });

  const { sbusOptions } = useSbuOptions(watchRegion as string);

  const onSubmit = useCallback((data: IProfilesFilterProps) => {
    setQuery({ ...data });
  }, []);

  function handleClearFilters() {
    reset({
      region: null,
      sbuId: null,
      typeId: null,
    });
    setQuery({});
  }

  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps
  ) {
    setPage(activePage as number);
  }

  function handleProfileFormModal(profile?: Profile) {
    const roles = profile?.roles?.map((item) => {
      return item?.id;
    });
    if (profile?.id) {
      setSelectedProfileData({
        id: profile?.id,
        region: profile?.sbu.region,
        description: profile?.description,
        sbuId: profile?.sbu.id,
        groupId: profile.group.id,
        rolesIds: roles,
        typeId: profile.type.id,
      });
    }
    setOpenProfileFormModal(true);
  }

  function handleProfileFormModalClose() {
    setSelectedProfileData(undefined);
    setOpenProfileFormModal(false);
  }

  function handleConfirmDeleteModalOpen(profileId: number) {
    setSelectedProfileId(profileId);
    setOpenConfirmDeleteModal(true);
  }

  function handleConfirmDeleteModalClose() {
    setSelectedProfileId(undefined);
    setOpenConfirmDeleteModal(false);
  }

  const handleDeleteProfile = useMutation(
    async (id: number) => {
      const response = await deleteProfile(id);
      handleConfirmDeleteModalClose();
      return response;
    },
    {
      onSuccess: () => {
        toast.success(translate('components.toast.success.delete'));
        queryClient.invalidateQueries('profiles');
      },
      onError: () => {
        toast.error(translate('components.toast.error.delete'));
      },
    }
  );

  return (
    <>
      <Container>
        <div className="page__title">{translate('menu.profiles')}</div>
        <Toolbar
          onAddClick={handleProfileFormModal}
          onApplyClick={handleSubmit(onSubmit)}
          onClearClick={handleClearFilters}
        >
          <FilterFormGroup>
            <ControllerFormDropdown
              controllerControl={control}
              controllerName="region"
              label={translate('pages.profiles.sbu')}
              options={sbuRegions}
              fluid
            />
            <ControllerFormDropdown
              controllerControl={control}
              controllerName="sbuId"
              label={translate('pages.profiles.countryLocation')}
              options={sbusOptions}
              required={!!watchRegion}
              error={errors.sbuId && translate('components.required')}
              disabled={!watchRegion}
              fluid
            />
            <ControllerFormDropdown
              controllerControl={control}
              controllerName="typeId"
              label={translate('pages.profiles.type')}
              options={useSelectParams(profilesTypes)}
              fluid
            />
          </FilterFormGroup>
        </Toolbar>
        {isLoading ? (
          <Loader active size="large" />
        ) : (
          <Table
            page={page}
            profiles={profiles}
            handlePageChange={handlePageChange}
            handleFormModalOpen={handleProfileFormModal}
            handleDeleteModalOpen={handleConfirmDeleteModalOpen}
          />
        )}
      </Container>
      {openProfileFormModal && (
        <ProfileFormModal
          initialValues={selectedProfileData}
          isOpen={openProfileFormModal}
          onRequestClose={handleProfileFormModalClose}
        />
      )}
      {openConfirmDeleteModal && selectedProfileId && (
        <Confirm
          header={translate('pages.profiles.confirmDeleteModalTitle')}
          content={translate('components.confirmDeleteModal.message')}
          cancelButton={{
            content: translate('components.confirmDeleteModal.cancel'),
          }}
          confirmButton={{
            content: translate('components.confirmDeleteModal.delete'),
            negative: true,
          }}
          open={openConfirmDeleteModal}
          onCancel={handleConfirmDeleteModalClose}
          onConfirm={() => {
            handleDeleteProfile.mutateAsync(selectedProfileId);
          }}
        />
      )}
    </>
  );
}

export { Profiles };
