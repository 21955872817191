import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Confirm, Loader, PaginationProps } from 'semantic-ui-react';

import { Toolbar } from '../../components/Toolbar';
import { translate } from '../../locales';
import { queryClient } from '../../services/queryClient';
import { getStructureList, deleteStructure } from '../../services/structure';
import {
  StructureDataForm,
  Structure as StructureType,
} from '../../types/structure';
import { StructureFormModal } from './components/FormModal';
import { Table } from './components/Table';
import { Container } from './styles';

function Structure() {
  const [openStructureFormModal, setOpenStructureFormModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [selectedStructureData, setSelectedStructureData] =
    useState<StructureDataForm>();
  const [selectedStructureId, setSelectedStructureId] = useState<number>();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({});

  const { data: structures, isLoading } = useQuery(
    ['structures', { page, ...query }],
    async () => {
      const response = await getStructureList({
        pageNumber: page - 1,
        pageSize: 10,
        ...query,
      });
      setQuery({});
      return response;
    }
  );

  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps
  ) {
    setPage(activePage as number);
  }

  function handleStructureFormModal(structure?: StructureType) {
    if (structure?.id) {
      setSelectedStructureData(structure);
    }
    setOpenStructureFormModal(true);
  }

  function handleStructureFormModalClose() {
    setSelectedStructureData(undefined);
    setOpenStructureFormModal(false);
  }

  function handleConfirmDeleteModalOpen(structureId: number) {
    setSelectedStructureId(structureId);
    setOpenConfirmDeleteModal(true);
  }

  function handleConfirmDeleteModalClose() {
    setSelectedStructureId(undefined);
    setOpenConfirmDeleteModal(false);
  }

  const handleDeleteStructure = useMutation(
    async (id: number) => {
      const response = await deleteStructure(id);
      handleConfirmDeleteModalClose();
      return response;
    },
    {
      onSuccess: () => {
        toast.success(translate('components.toast.success.delete'));
        queryClient.invalidateQueries('structures');
      },
      onError: () => {
        toast.error(translate('components.toast.error.delete'));
      },
    }
  );

  function getTable() {
    if (isLoading) {
      return <Loader size="large" active />;
    }
    return (
      <Table
        page={page}
        structures={structures}
        handlePageChange={handlePageChange}
        handleFormModalOpen={handleStructureFormModal}
        handleDeleteModalOpen={handleConfirmDeleteModalOpen}
      />
    );
  }

  return (
    <>
      <Container>
        <div className="page__title">{translate('menu.structure')}</div>
        <Toolbar
          onClearClick={() => undefined}
          onApplyClick={() => undefined}
          onAddClick={handleStructureFormModal}
          disableFilter
        />
        {getTable()}
      </Container>
      {openStructureFormModal && (
        <StructureFormModal
          initialValues={selectedStructureData}
          isOpen={openStructureFormModal}
          onRequestClose={handleStructureFormModalClose}
          closeModalOnSave={() => setOpenStructureFormModal(false)}
        />
      )}
      {openConfirmDeleteModal && selectedStructureId && (
        <Confirm
          header={translate('pages.structure.confirmDeleteModalTitle')}
          content={translate('components.confirmDeleteModal.message')}
          cancelButton={{
            content: translate('components.confirmDeleteModal.cancel'),
          }}
          confirmButton={{
            content: translate('components.confirmDeleteModal.delete'),
            negative: true,
          }}
          open={openConfirmDeleteModal}
          onCancel={handleConfirmDeleteModalClose}
          onConfirm={() => {
            handleDeleteStructure.mutateAsync(selectedStructureId);
          }}
        />
      )}
    </>
  );
}

export { Structure };
