import { useQuery } from 'react-query';
import { Header, Modal, ModalProps, Table } from 'semantic-ui-react';

import { getTrainingByEmployeeId } from '../../../../services/employees';

interface EmployeeFormModalProps extends ModalProps {
  isOpen: boolean;
  employeeId?: number;
  onRequestClose: () => void;
}

export function EmployeeTrainingModal({
  isOpen,
  employeeId,
  onRequestClose,
  ...rest
}: EmployeeFormModalProps) {
  const { data: employeeTrainings } = useQuery(
    'EMPLOYEE_TRAINING',
    async () => getTrainingByEmployeeId({ employeeId }),
    { enabled: !!employeeId }
  );

  return (
    <Modal
      {...rest}
      open={isOpen}
      size="large"
      onClose={onRequestClose}
      closeIcon
      closeOnDimmerClick={false}
    >
      <Modal.Header content="Employee Training" />

      <Modal.Content>
        <Table unstackable compact size="small" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="4">
                <Header content="WESLEY DIOGO" />
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell content="Country" />
              <Table.HeaderCell content="Register Number" />
              <Table.HeaderCell content="Job Title" />
              <Table.HeaderCell content="Business Line" />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell content="BR - CENTRAL OFFICE" />
              <Table.Cell content="678901" />
              <Table.Cell content="DESENVOLVEDOR WEB E MOBILE" />
              <Table.Cell content="TECNOLOGIA DA INFORMAÇÃO" />
            </Table.Row>
          </Table.Body>
        </Table>

        <Table unstackable compact size="small" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="6">
                <Header content="TRAININGS" size="tiny" />
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell content="Id" />
              <Table.HeaderCell content="Category" />
              <Table.HeaderCell content="Component" />
              <Table.HeaderCell content="Description" />
              <Table.HeaderCell content="Type" />
              <Table.HeaderCell content="Value" />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {employeeTrainings?.content ? (
              employeeTrainings?.content?.map((item, index) => (
                <Table.Row key={item?.id}>
                  <Table.Cell content={index + 1} />
                  <Table.Cell content="BR - CENTRAL OFFICE" />
                  <Table.Cell content="678901" />
                  <Table.Cell content={item?.description} />
                  <Table.Cell content="TECNOLOGIA DA INFORMAÇÃO" />
                  <Table.Cell content="678901" />
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan="6" textAlign="center">
                  <Header content="Not data found" />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
}
