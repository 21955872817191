import { useQuery } from 'react-query';

import { getMandatorySbu, getMandatorySbuFilter } from '../services/mandatory';

interface IProps {
  watchSbuId?: number;
  watchRegion?: string;
  watchTrainingId?: number;
}

export function useMandatoryWithTrainingIdOptions({
  watchSbuId,
  watchRegion,
  watchTrainingId,
}: IProps) {
  const { data: mandatoryOptions } = useQuery(
    ['year', watchSbuId],
    async () => {
      if (!watchTrainingId) {
        const data = await getMandatorySbu({ sbuId: watchSbuId });
        return [
          {
            key: data.id,
            text: data.year.split('-')[0],
            value: data.id,
          },
        ];
      }

      if (watchTrainingId) {
        const data = await getMandatorySbuFilter({ sbuId: watchSbuId });
        return data?.map(({ id, year }) => ({
          key: id,
          text: year.split('-')[0],
          value: id,
        }));
      }

      return undefined;
    },
    { enabled: !!watchSbuId && !!watchRegion }
  );

  return {
    mandatoryOptions,
  };
}
