import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { translate } from '../../../locales';
import { queryClient } from '../../../services/queryClient';
import { deleteTraining } from '../../../services/training';

interface IProps {
  handleConfirmDeleteModalClose(): void;
}

export function useHandleDeleteTraining({
  handleConfirmDeleteModalClose,
}: IProps) {
  const handleDeleteTraining = useMutation(
    async (id: number) => {
      const response = await deleteTraining(id);
      handleConfirmDeleteModalClose();
      return response;
    },
    {
      onSuccess: () => {
        toast.success(translate('components.toast.success.delete'));
        queryClient.invalidateQueries('trainings');
      },
      onError: () => {
        toast.error(translate('components.toast.error.delete'));
      },
    }
  );

  return {
    handleDeleteTraining,
  };
}
