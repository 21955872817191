import styled from 'styled-components';

interface IFilterbarProps {
  show: boolean;
  top?: number;
}

export const Container = styled.div<IFilterbarProps>`
  background-color: ${({ theme }) => theme.palette.BACKGROUND.WHITE};
  width: 250px;
  margin-top: 0px;
  border-radius: 5px;
  box-shadow: -1px 1px 6px 0px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};
  position: fixed;
  top: ${({ top }) => (top ? `${top}px` : '196px')};
  right: ${({ show }) => (show ? '50px' : '-260px')};
  transition: all ease 0.2s;
  z-index: 2;

  form {
    max-height: 60vh;
    display: flex;
    flex-direction: column;

    .filter__header {
      .filter__logo {
        display: none;
      }
      .filter__title {
        font-size: 1.4em;
        font-weight: bold;
        padding: 8px 16px;
        border-bottom: 1px solid
          ${({ theme }) => theme.palette.BACKGROUND.LIGHT};
        opacity: 0.8;
      }
      .filter__close {
        display: none;
      }
    }

    .filter__body {
      height: 100%;
      overflow-y: auto;
      ::-webkit-scrollbar-track {
        background: none;
      }
      ::-webkit-scrollbar {
        width: 4px;
        scroll-padding-top: 100px;
      }
    }

    .filter__submit {
      position: relative;
      bottom: 0;

      .button {
        background-color: ${({ theme }) => theme.palette.GENERAL.PRIMARY};
        color: ${({ theme }) => theme.palette.GENERAL.SECONDARY};
      }
    }
  }
  @media (max-width: 460px) {
    height: 100%;
    width: 100vw;
    margin-top: 0;
    padding: 0;
    border-radius: 0;
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;

    form {
      min-height: none;
      height: 100vh;
      max-height: none;
      position: relative;
      top: 0;
      bottom: 0;

      .filter__header {
        background-color: ${({ theme }) => theme.palette.GENERAL.PRIMARY};
        height: 56px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .filter__logo {
          display: block;
        }
        .filter__title {
          color: ${({ theme }) => theme.palette.BACKGROUND.LIGHT};
          border-bottom: 0;
          opacity: 1;
        }
        .filter__close {
          display: block;
          color: ${({ theme }) => theme.palette.GENERAL.SECONDARY};
        }
      }
      .form-body {
        height: 100%;
        padding: 14px;
        flex: 1;
      }
      .filter__submit {
        position: relative;
        bottom: 0;
        padding: 16px;
        box-shadow: 0 -3px 10px -5px
          ${({ theme }) => theme.palette.LAYOUT.SIDEBAR};
      }
    }
  }

  .filterbar {
    height: 100%;
    width: 100px;
    position: relative;
    right: -20px;
  }
`;
