import {
  Button,
  Dropdown,
  Icon,
  Table as TableSemantic,
} from 'semantic-ui-react';

interface TableBodyProps {
  structure: any;
  handleFormModalOpen: (structure: any) => void;
  handleDeleteModalOpen: (structureId: number) => void;
}

function TableBody({
  structure,
  handleFormModalOpen,
  handleDeleteModalOpen,
}: TableBodyProps) {
  return (
    <TableSemantic.Body className="tbody">
      {structure?.map((item: any, index: number) => {
        return (
          <TableSemantic.Row key={item.id} className="tr noRowspan">
            <TableSemantic.Cell className="td id" textAlign="center">
              {index + 1}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td sbu" textAlign="center">
              {item.region}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td country" textAlign="center">
              {item.country}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td language" textAlign="center">
              {item.language}
            </TableSemantic.Cell>

            <TableSemantic.Cell
              className="td action structure"
              textAlign="center"
            >
              <Dropdown
                icon={null}
                pointing="right"
                className="dropdown structure"
                trigger={<Icon name="ellipsis vertical" size="large" />}
              >
                <Dropdown.Menu>
                  <span className="action-buttons structure">
                    <Button
                      icon
                      color="yellow"
                      size="mini"
                      className="filter-list structure edit"
                      onClick={() => {
                        handleFormModalOpen(item);
                      }}
                    >
                      <Icon name="pencil" />
                    </Button>
                    <Button
                      icon
                      size="mini"
                      color="red"
                      className="filter-list structure delete"
                      onClick={() => {
                        handleDeleteModalOpen(item.id);
                      }}
                    >
                      <Icon name="trash" />
                    </Button>
                  </span>
                </Dropdown.Menu>
              </Dropdown>
            </TableSemantic.Cell>
          </TableSemantic.Row>
        );
      })}
    </TableSemantic.Body>
  );
}

export { TableBody };
