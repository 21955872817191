import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f1f1f1;
  min-width: 350px;
  max-width: 350px;
  flex: 1;
  padding: 10px 20px 20px;
  border-radius: 5px;
  box-shadow: 5px 0 3px -3px ${({ theme }) => theme.palette.BACKGROUND.SHADOW};

  @media print {
    border-radius: 0;
    box-shadow: none;
  }

  .welcome__title {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 26.13px;
    margin-bottom: 20px;

    .welcome__title--userName {
      text-transform: capitalize !important;
    }
  }

  .welcome__description {
    img {
      border-radius: 5px;
    }
  }

  .wellcome__description-card {
    width: 100%;
    padding: 10px 0;
    border-radius: 5px;
  }

  .wellcome__directorCard {
    height: 100px;
    width: 100%;
    padding: 10px;
    margin: 16px 0;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .wellcome__directorCard--nameAndJobtitle {
      margin-left: 10px;
      padding-left: 10px;
      border-left: 2px solid #cccccc;

      display: flex;
      flex-direction: column;
      justify-content: center;

      .wellcome__directorCard--name {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 0.2rem;
      }

      .wellcome__directorCard--jobtitle {
        font-size: 1.2rem;
        margin-bottom: 0.2rem;
      }
    }

    .wellcome__directorCard--image {
      width: 80px;
      height: 80px;

      border-radius: 50%;
      /* border: 2px solid ${({ theme }) => theme.palette.GENERAL.SECONDARY}; */
    }
  }

  .wellcome__card-important-warning {
    width: 100%;
    padding: 10px;
    margin: 16px 0;
    border-radius: 5px;

    ul {
      list-style-position: inside;

      li {
        list-style-type: disc;
        padding: 0.2rem 0;
      }
    }
  }
`;
