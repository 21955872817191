import { useState } from 'react';
import {
  Table as TableSUI,
  Pagination,
  PaginationProps,
  Icon,
} from 'semantic-ui-react';

import { TableNoDataFound } from '../../../../components/TableNoDataFound';
import { translate } from '../../../../locales';
import { EmployeesResponseProps } from '../../../../types/employees';
import { EmployeeTrainingModal } from '../EmployeeTrainingModal';
import { TableBody } from '../TableBody';

interface ITableProps {
  employees?: EmployeesResponseProps;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const FIELD_REQUIRED_MESSAGE = 'th idColTr';

function Table({ employees, page, setPage }: ITableProps) {
  const [isEmployeeTrainingModalOpen, setIsEmployeeTrainingModalOpen] =
    useState(false);
  const [employeeIdSelected, setEmployeeIdSelected] = useState<number>();

  function handleEmployeeTrainingModalOpen(id: number) {
    setIsEmployeeTrainingModalOpen(true);
    setEmployeeIdSelected(id);
  }

  function handlePageChange(
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps
  ) {
    setPage(activePage as number);
  }

  function handleEmployeeTrainingModalClose() {
    setIsEmployeeTrainingModalOpen(false);
    setEmployeeIdSelected(undefined);
  }

  const cells = [
    {
      id: 1,
      className: 'th idColTr idCol',
      content: translate('pages.employees.id'),
      textAlign: 'center',
      width: 1,
      scope: 'col',
    },
    {
      id: 2,
      className: 'th idColTr idCol',
      content: translate('pages.employees.userId'),
      textAlign: 'center',
      scope: 'col',
    },
    {
      id: 3,
      className: FIELD_REQUIRED_MESSAGE,
      content: translate('pages.employees.name'),
      textAlign: 'left',
      scope: 'col',
    },
    {
      id: 4,
      className: FIELD_REQUIRED_MESSAGE,
      content: translate('pages.employees.jobTitle'),
      textAlign: 'left',
      scope: 'col',
    },
    {
      id: 5,
      className: FIELD_REQUIRED_MESSAGE,
      content: translate('pages.employees.costCenter'),
      textAlign: 'left',
      scope: 'col',
    },
    {
      id: 6,
      className: FIELD_REQUIRED_MESSAGE,
      content: translate('pages.employees.referenceYear'),
      textAlign: 'center',
      scope: 'col',
    },
    {
      id: 7,
      className: 'th action',
      content: <Icon name="cog" size="large" />,
      textAlign: 'center',
      scope: 'col',
    },
  ];

  return (
    <>
      <div className="table__scrollable">
        <TableSUI className="table" unstackable>
          <TableSUI.Header className="thead">
            <TableSUI.Row className="tr">
              {cells.map(
                ({ id, className, content, textAlign, width, scope }) => (
                  <TableSUI.HeaderCell
                    key={id}
                    className={className}
                    textAlign={textAlign as any}
                    width={width as any}
                    scope={scope}
                  >
                    {content}
                  </TableSUI.HeaderCell>
                )
              )}
            </TableSUI.Row>
          </TableSUI.Header>

          {employees?.content && (
            <TableBody
              employees={employees?.content}
              handleEmployeeTrainingModalOpen={handleEmployeeTrainingModalOpen}
            />
          )}
        </TableSUI>
      </div>

      <div className="table__pagination">
        {employees?.content ? (
          <Pagination
            page={page}
            activePage={page}
            totalPages={employees?.totalPages ?? 0}
            onPageChange={handlePageChange}
          />
        ) : (
          <TableNoDataFound />
        )}
      </div>

      <EmployeeTrainingModal
        isOpen={isEmployeeTrainingModalOpen}
        employeeId={employeeIdSelected}
        onRequestClose={handleEmployeeTrainingModalClose}
      />
    </>
  );
}

export { Table };
