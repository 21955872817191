import { MandatoryDetailsResponseProps } from '../types/mandatoryDetails';
import api from './api';

interface IParamsProps {
  mandatoryId?: number;
  sbuId?: number;
  za?: string;
}

export async function getMandatoryDetailsService({
  mandatoryId,
  sbuId,
  za,
}: IParamsProps): Promise<MandatoryDetailsResponseProps> {
  const response = await api.get('/time-amount/employee/userDetails', {
    params: {
      mandatoryId: Number(mandatoryId),
      sbuId: Number(sbuId),
      za,
    },
  });

  const mandatoryDetails: MandatoryDetailsResponseProps = response.data;

  return {
    ...mandatoryDetails,
    data: mandatoryDetails.data.map((training) => ({
      ...training,
      data: training.data.map((trainingItem) => ({
        ...trainingItem,
        timeValue: Number(trainingItem.timeValue.toFixed(2)),
      })),
    })),
  };
}
