import styled from 'styled-components';

export const Container = styled.body`
  display: block;
`;

export const Page = styled.div`
  background-color: #ffffff;
  width: 297mm;
  height: 210mm;
  border: 1px #00000030 solid;

  display: flex;
  gap: 20px;
  page-break-after: always !important;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
