import { Icon as FluentIcon } from '@fluentui/react/lib/Icon';
import { useState } from 'react';
import { Icon } from 'semantic-ui-react';

import { TrainingItem } from '../../../../types/mandatoryDetails';
import { TrainingDetailsModal } from './components/TrainingDetailsModal';
import {
  Container,
  GridCardContent,
  GridCardHeader,
  GridCardItem,
} from './styles';

interface IProps {
  title: string;
  totalType?: number;
  trainings?: TrainingItem[];
  backgroundColor?: string;
}

export function GridCard({
  title,
  totalType,
  trainings,
  backgroundColor,
}: IProps) {
  const [isOpen, setIsOpen] = useState(() => {
    if (totalType === 0) {
      return false;
    }

    return true;
  });
  const [isTrainingDetailsModalOpen, setIsTrainingDetailsModalOpen] =
    useState(false);
  const [trainingDetailsModalData, setTrainingDetailsModalData] =
    useState<TrainingItem>();

  function toggleCollapse() {
    setIsOpen((prev) => !prev);
  }

  function handleTrainingDetailsModalOpen(data: TrainingItem) {
    setIsTrainingDetailsModalOpen(true);
    setTrainingDetailsModalData(data);
  }

  function handleTrainingDetailsModalClose() {
    setIsTrainingDetailsModalOpen(false);
    setTrainingDetailsModalData(undefined);
  }

  return (
    <>
      <Container style={{ backgroundColor }}>
        <GridCardHeader onClick={toggleCollapse} aria-hidden="true">
          <span style={{ display: 'flex', gap: '0.5rem' }}>
            <Icon
              link
              size="large"
              name={isOpen ? 'angle down' : 'angle right'}
            />
            <div className="gridCard__header--title">{title}</div>
          </span>

          <span>{totalType}h</span>
        </GridCardHeader>

        <GridCardContent isOpen={isOpen}>
          {trainings?.map(({ icon, shortDescription, timeValue, values }) => (
            <GridCardItem
              key={shortDescription}
              isOpen={isOpen}
              onClick={() =>
                handleTrainingDetailsModalOpen({
                  icon,
                  shortDescription,
                  timeValue,
                  values,
                })
              }
            >
              <div className="gridCardItem__title">
                <FluentIcon iconName={icon} style={{ fontSize: 16 }} />
                <div className="gridCardItem__title--text">
                  {shortDescription}
                </div>
              </div>
              <div className="gridCardItem__hours">{`${timeValue}h`}</div>
            </GridCardItem>
          ))}
        </GridCardContent>
      </Container>
      <TrainingDetailsModal
        open={isTrainingDetailsModalOpen}
        onClose={handleTrainingDetailsModalClose}
        data={trainingDetailsModalData}
      />
    </>
  );
}
