import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Icon,
  Popup,
  Table as TableSemantic,
} from 'semantic-ui-react';

import { EmployeesDataProps } from '../../../../types/employees';

interface TableBodyProps {
  employees?: EmployeesDataProps[];
  handleEmployeeTrainingModalOpen(id: number): void;
}

function TableBody({ employees }: TableBodyProps) {
  const navigate = useNavigate();

  function handleClick({ userId, mandatoryId, sbuId }: any) {
    navigate(`/development/${mandatoryId}/${sbuId}`, {
      state: userId,
    });
  }

  return (
    <TableSemantic.Body className="tbody">
      {employees?.map((item, index: number) => {
        return (
          <TableSemantic.Row key={item.id} className="tr noRowspan">
            <TableSemantic.Cell className="td table-idTr" textAlign="center">
              {index + 1}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td" textAlign="center">
              {item.userId}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td">{item.name}</TableSemantic.Cell>

            <TableSemantic.Cell className="td">
              {item.jobTitle}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td">
              {item.businessLine}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td" textAlign="center">
              {String(item.referenceYear).split('.')[0]}
            </TableSemantic.Cell>

            <TableSemantic.Cell className="td action">
              <div>
                <Dropdown
                  pointing="right"
                  trigger={<Icon name="ellipsis vertical" size="large" />}
                  icon={null}
                >
                  <Dropdown.Menu>
                    <span className="action-buttons">
                      <Popup
                        inverted
                        content="Report"
                        trigger={
                          <Button
                            icon="clipboard"
                            size="mini"
                            onClick={() =>
                              handleClick({
                                userId: item.userId,
                                mandatoryId: item.mandatoryId,
                                sbuId: item.sbuId,
                              })
                            }
                          />
                        }
                      />
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </TableSemantic.Cell>
          </TableSemantic.Row>
        );
      })}
    </TableSemantic.Body>
  );
}

export { TableBody };
