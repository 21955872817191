import { memo } from 'react';
import { Control, Controller } from 'react-hook-form';

import { Upload, UploadProps } from '../Inputs/Upload';

type FormUploadProps = UploadProps & {
  control?: Control<any, object>;
  name: string;
};

export const FormUpload = memo(function FormUpload({
  control,
  name,
  ...rest
}: FormUploadProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <Upload
          onChange={(event) => {
            const file = event.target.files?.item(0);

            if (file) {
              onChange(file);
            }
          }}
          {...rest}
        />
      )}
    />
  );
});
