import axios from 'axios';

export async function getMonochromeColors(initialColor: string, range: number) {
  const response = await axios.get(`https://www.thecolorapi.com/scheme`, {
    params: {
      hex: initialColor,
      mode: 'monochrome',
      count: range,
    },
  });

  return response.data.colors.map(({ hex }: any) => hex.value);
}
