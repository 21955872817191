import { Modal, StrictModalProps, Table } from 'semantic-ui-react';

import { translate } from '../../../../../../locales';
import { TrainingItem } from '../../../../../../types/mandatoryDetails';
import { ModalContainer } from './styles';

interface IProps extends StrictModalProps {
  data?: TrainingItem;
}

export function TrainingDetailsModal({ data, ...rest }: IProps) {
  return (
    <ModalContainer {...rest} size="tiny" closeIcon>
      <Modal.Header>
        <span>{data?.shortDescription}</span>
        <span>{data?.timeValue}h</span>
      </Modal.Header>
      <Modal.Content>
        <Table compact celled selectable striped>
          <Table.Header>
            <Table.HeaderCell>
              {translate(
                'pages.development.reportPages.pageOne.trainingDetailsModal.item'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" singleLine>
              {translate(
                'pages.development.reportPages.pageOne.trainingDetailsModal.value'
              )}
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {data?.values?.map(({ description, value }) => (
              <Table.Row key={description}>
                <Table.Cell content={description} />
                <Table.Cell content={value} textAlign="center" />
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
    </ModalContainer>
  );
}
