import axios from 'axios';

import { ENV } from '../types/env';

const api = axios.create({
  baseURL: ENV.BASE_API_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      Number(error.response.data.businessCode) === 105 ||
      Number(error.response.data.businessCode) === 102
    ) {
      window.location.href = ENV.LOGIN_URL;
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default api;
