import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.GENERAL.PRIMARY};
  background-image: linear-gradient(#004ea8, #feda00);
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
`;

export const LogoGoodyear = styled.img`
  width: 45%;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
`;

export const Animation = styled.img`
  width: 15%;
`;
