import { useQuery } from 'react-query';

import { getMessagesList, getMessageTypeByName } from '../services/messages';

interface UseMessageTypeProps {
  messageTypeName: string;
  lastMandatoryId?: number;
}

export function useMessageByType({
  messageTypeName,
  lastMandatoryId,
}: UseMessageTypeProps) {
  const { data: messageTypeId } = useQuery(
    messageTypeName,
    async () => (await getMessageTypeByName(messageTypeName)).id
  );

  const { data: message, isLoading } = useQuery(
    [lastMandatoryId, messageTypeName, messageTypeId],
    async () => {
      const res = await getMessagesList({
        mandatoryId: lastMandatoryId,
        typeId: messageTypeId,
        page: undefined,
        size: undefined,
      });

      return res.content?.[0].content;
    },
    {
      enabled: !!messageTypeId,
      retry: false,
    }
  );

  return {
    message,
    isLoading,
  };
}
