import { Control, Controller } from 'react-hook-form';
import {
  Dropdown,
  DropdownItemProps,
  StrictFormDropdownProps,
} from 'semantic-ui-react';

type SelectAutocompleteProps = Omit<
  StrictFormDropdownProps,
  'control' | 'error'
> & {
  controllerControl?: Control<any>;
  controllerName?: string;
  options: DropdownItemProps[];
  required?: boolean;
  error?: any;
};

function SelectAutocomplete({
  controllerControl,
  controllerName,
  options,
  required,
  ...rest
}: SelectAutocompleteProps) {
  return (
    <Controller
      control={controllerControl}
      name={controllerName || ''}
      rules={{
        required,
      }}
      render={({ field: { value, onChange } }) => (
        <Dropdown
          clearable
          search
          selection
          value={value}
          options={options ?? []}
          onChange={(_, data) => onChange(data.value)}
          {...rest}
        />
      )}
    />
  );
}

export { SelectAutocomplete };
