export default {
  palette: {
    GENERAL: {
      PRIMARY: '#004EA8',
      SECONDARY: '#FEDA00',
      PRIMARY_HOVER: '#00397a',
      TERTIARY: '#232C64',
    },
    LAYOUT: {
      HEADER: '#27262E',
      SIDEBAR: '#333333',
      CLEAR_BUTTON: '#4183C4',
    },
    BACKGROUND: {
      DEFAULT: '#F8F9FB',
      LIGHT: '#F2F2F2',
      WHITE: '#FFFFFF',
      HINT: 'rgba(0, 0, 0, 0.24)',
      SHADOW: 'rgba(0, 0, 0, 0.1)',
    },
    TEXT: {
      PRIMARY: 'rgba(0, 0, 0, 0.87)',
      SECONDARY: 'rgba(0, 0, 0, 0.54)',
      CONTRAST_ONE: '#000000',
      CONTRAST_TWO: '#FFFFFF',
    },
    SHADES_OF_GREY: {
      DEFAULT: '#596070',
      CLEAR: '#E7E9EB',
      HOVER: '#E1E1E1',
      DARK: '#32363F',
      MEDIUM: '#999999',
      POLISHED: '#7F7F7F',
      IRON: '#ABABAB',
      PLATINUM: '#616161',
      CEMENT: 'rgba(221, 221, 221, 0.4)',
      SHADOW: 'rgba(0, 0, 0, 0.25)',
    },
    CHARTS: {
      PINK: '#FF63EA',
      BLUE: 'rgba(54, 162, 235, 1)',
      YELLOW: 'rgba(255, 206, 86, 1)',
      GREEN: 'rgba(75, 192, 192, 1)',
      PURPLE: 'rgba(153, 102, 255, 1)',
      ORANGE: 'rgba(255, 159, 64, 1)',
    },
    OTHERS: {
      LIGHT_BLUE: '#0066DD',
      SAPPHIRE: '#27457B',
      AZURE: '#3762AF',
      HEAVENLY: '#048CD1',
      TOPAZ: '#99CE4A', // MODIFICAR PARA AMARELO
      YELLOW: '#99CE4A', // MODIFICAR PARA AMARELO
      SAND: '#8AB24E', // MODIFICAR PARA AMARELO
    },
  },
};
