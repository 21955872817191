import { CircleAround, Container } from './styles';

interface IProps {
  categories?: string[];
  total?: number;
}

export function TrainingTypesChart({ categories, total }: IProps) {
  const unitDegrees = 360 / (categories?.length || 0);
  const degreesList: number[] = [];
  let currentDegrees = 0;

  return (
    <Container>
      <div className="circle center">{total ?? 0}h</div>

      {categories?.map((category, index) => {
        currentDegrees += unitDegrees;

        degreesList.push(currentDegrees);

        return (
          <CircleAround
            key={category}
            className={`circle around${index}`}
            deg={degreesList[index]}
          >
            <span>{category}</span>
          </CircleAround>
        );
      })}
    </Container>
  );
}
