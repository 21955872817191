import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.BACKGROUND.DEFAULT};
  min-height: 100vh;
  padding: 74px 50px 20px;
  margin-left: 63px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 460px) {
    margin: 54px 0 0;
    padding: 0;
  }
`;
